<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">Cierre Mensual</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1D5YOPVGJERbfq0eC4nTZid-TJdoIsw9A/view?usp=sharing">APLICACION Y-DISPONIBILIDAD-DEL-PRESUPUESTO</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                >BALANCE PRESUPUESTARIO</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EHbmeB7-oqlgrKD01W13BLqkYpQ_i51G/view?usp=sharing">BALANZA COMPROBACION ACUMULADA</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1mtxlGOZZsiDNNmbqlQ8cW1IbWthqlbC9/view?usp=sharing" class="link">BALANZA DE COMPROBACION MENSUAL</a></li>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1iOxF9d3p6kpiLrXm4z95xG2aSQ-nDfGj/view?usp=sharing">ENDEUDAMIENTO NETO </a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1FBA4o4hUFAWmGd5NBseM7fIzsM12vwZG/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1foFBKMx_8-yPx_3xMxT87oeat3OHfwbQ/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DEL EJERCICIO</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                               >ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                >ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                >ESTADO ANALITICO DE INGRESOS DETALLADO</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1wKFFq_qH_GKh8m8I3fGcp8cDIfxWm1tz/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/13KW4eiwLRK1EFo5bTxzWXFBv6mjXhoPn/view?usp=sharing">ESTADO ANALITICO DE LA DEUDA Y OTROS PASIVOS</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16itUjVdbdlxAgFdv5-GMISUn26cIJsj9/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO POR RUBROS </a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1BX5PIrzhsWdCUVYGT8-QU71EZwPLasrQ/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1zk5RBdkFQpzkjS2W9QJKam3WKmH5FzPY/view?usp=sharing"> ESTADO DE ACTIVIDADES POR RUBROS </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1kRq4mbpXTf7TW6-dQARhxQRcvND_kXac/view?usp=sharing">ESTADO DE ACTIVIDADES</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Ls2VAODPvccK7PJ_n5-hxiWH0VD-3xfl/view?usp=sharing">ESTADO DE CAMBIOS EN LA SITUACION FINANCIERA</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1VwxZ99oB-CehEmW9nFMDpavI1TbESbMG/view?usp=sharing">ESTADO DE FLUJOS DE EFECTIVO</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                               >ESTADO DE SITUACION FINANCIERA DETALLADO</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1-dwtFbecZKZJWHfHOMrZhexImGLSec5h/view?usp=sharing">ESTADO DE SITUACION FINANCIERA</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1MnMgrqg_B_OnzvIM6yXwzIDl7b0ubSiO/view?usp=sharing">ESTADO DE SITUACION FINANCIERA POR RUBROS </a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1_G4VSY5xZllt51R5UmwaNKWF17cT_-t3/view?usp=sharing">ESTADO DE VARIACION EN HACIENDA PUBLICA</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1p2hjezkbC1tEHKBTi5R8PSvJFT4sSj6-/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DE AÑOS ANTERIORES</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1NCUdE7273n1oMte5If9RmW9m8_MLUqCH/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1UJi0LMIpyjTDS2YDw7tpJHYF1qJUZEf_/view?usp=sharing">INDICADORES DE POSTURA FISCAL</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                               >INFORME ANALITICO DE OBLIGACIONES DIFERENTES DE FINANCIAMIENTOS</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                >INFORME DE LA DEUDA PUBLICA Y OTROS PASIVOS</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1tXR2nM1Cg3-nYyM2BhB7vUgisZsmBPcc/view?usp=sharing">INFORME SOBRE PASIVOS CONTIGENTES</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Ro7q0sP_kNNSNqtYN-0Qw8L8OQlwd-DL/view?usp=sharing">INTEGRACION DEL PRESUPUESTO</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1_A-DX6XQk2boIuv0Xr5CjXHUqJPNtXve/view?usp=sharing">INTERESES DE LA DEUDA</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1oFkiRBioszZ_8m5NB8fOJU8MdYS0Ir6d/view?usp=sharing">NOTAS DE MEMORIA</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1BfLuOO8PehzSGHQL_ixEhWeK6QsXkbKt/view?usp=sharing">RELACION DE CUENTAS BANCARIAS PRODUCTIVAS ESPECIFICAS</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Co34wNfNgKloH0_Ji_nt2f1PVNL5xovR/view?usp=sharing">RELACION DE ESQUEMAS BURSATILES Y COBERTURA FINANCIERA</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
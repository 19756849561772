<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PRESUPUESTARIOS</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href=""></a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
            
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href=""></a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="" class="link"></a></li>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1YjokPHdyicqm9EvPxXTu4Tv4o76JWfy0/view?usp=sharing">RPA-1-Edo-Analit-Clasif-Objt-Gto</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1tR_etfkizDvJ9fjXKrAKO2AkN8I2FJEI/view?usp=sharing">RPA-2-Edo-Analit-Clasif-Economica</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                         <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1LltOIVDb4gla1sZ0KTWpuUus2r-7n260/view?usp=sharing">RPA-3-Edo-Analit-Clasif-Admitiva-Org</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1nIK3GBXrN_IF4JZwjQ2Lk6nQGQuzy5Is/view?usp=sharing">RPA-4-Edo-Analit-Clasif-Admitiva-pod</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1llEDAZWx6HGi8HAqtlj7d20_eenAxmgR/view?usp=sharing">RPA-5-Edo-Analit-Clasif-Admitiva-ent</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cNFO2pxvQSv0P5Lo-JaZ1kBIMbLVZ3af/view?usp=sharing">RPA-6-Edo-Analit-Clasif-funcional</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PROGRAMATICOS</p>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/174-c8r6pIFT17TxhS-oFNaVHeCXg2S7v/view?usp=sharing">PROGRAMAS PROYECTOS INVERSION</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1UojHtmFzNnPQwrbBNg4HjxUm7CzyJRra/view?usp=sharing">CATEGORIA PROGRAMATICA</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">REINTEGROS FEDERALIZADOS</p>
                    </div>
                </div>
                <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1__ai9QE2CvVrGKfpPwBrrcHOfYvUEdHH/view?usp=sharing">FORMATO FEDERALIZADO REINTEGROS</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION LEY DISCIPLINA FINANCIERA</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jpdr00t6dOJrQm5Tu-F3v7Mel5ChmPZd/view?usp=sharing">LDF-6a-Edo-Analit-Detallado-Clasif-Obj-Gto</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1YbyvXkorjYTHotEDBye5vwUULL4JIPjm/view?usp=sharing">LDF-6b-Edo-Analit-Detallado-Clasif-Admitiva</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1JWWvdpvaG9EDpNS15K60TUXCXPMJ_GL9/view?usp=sharing">LDF-6c-Edo-Analit-Detallado-Clasif-funcional</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ObT4NoJkUNj9W96MmAfYmhw90JMNUWbO/view?usp=sharing">LDF-6d-Edo-Analit-Detallado-Clasif-Serv-pers</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16Mv6bSxrxr38AuBfnVGM3rCFCplQ4vDI/view?usp=sharing">GUÍA CUMLPLIMIENTO LDF ENTIDADES FEDERATIVAS Y MPIOS</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION DE INTEGRACION Y ANALISIS</p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1UBPu0QdQoG5Vd3T1qQWE3OgdsPZYNCq_/view?usp=sharing">FONDO DE FISCALIZACIÓN Y RECAUDACIÓN EP-5814</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1006qOjwBDoCqA4eAU7xNfEXJ-cQcSuV0/view?usp=sharing">Analisis-Recursos-Federales-Etiquetados</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1tsagiFZuyCNTpRNXv71SEcEHVKv5Hf0j/view?usp=sharing">ANÁLISIS PRINCIPALES VARIACIONES Y ADECUACIONES AL PRESUPUES</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rHgZgxibM9CMRtmrDMSpgNjV-b10scli/view?usp=sharing">ANÁLISIS DE LOS PROYECTOS DE GASTO DE CAPITAL MAYOR</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1pJXpQBtE9pydfuEEGLduHozkE-m0GeMP/view?usp=sharing">Aplicacion-Presupuesto-por-rubros</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1peKBhokeXKypf9lEhA-qZxIQmlk5Hw9O/view?usp=sharing">FONDO GENERAL DE PARTICIPACIONES EP-5811</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/18M6FKNQbfJ_VeMU89Ib2RZ0vL3pBt_7D/view?usp=sharing">IMPUESTO A LA VENTA FINAL DE GASOLINA Y DIÉSEL EP-5817</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1pJXpQBtE9pydfuEEGLduHozkE-m0GeMP/view?usp=sharing">Cierre 2020</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1-tRiHypRg1Ur8XMx4n9JfysBmQycL0W8/view?usp=sharing">COMPARATIVO Y ANÁLISIS PRESUP DEVENGADO POR OBJETO DEL GASTO</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1o29m5eAdNmFbbgo85SC-3KNfWR461BC4/view?usp=sharing">ANÁLISIS DE PARTIDAS POR CLASIFICACIÓN ADMITIVA EP-01</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1AaY6pqWRWVCWc1B8TlSr76iTC-hMXlFE/view?usp=sharing">CAPÍTULOS, CONCEPTOS, PARTIDA GENÉRICA ESPECÍFICA EP-03</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1iJlFCVQ5e12XUD78yQOj-0EUdesMnoah/view?usp=sharing">EP-05-Etiquetado-Rec-federales-Clasif-Admitiv</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1g-ujckP1mTeX8VgZC9DeTo9BkPtQz49d/view?usp=sharing">ISR PARTICIPABLE ESTATAL EP-5818</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1MkEOc2JJAVtUusoPnKNean8G1bVWTe8d/view?usp=sharing">RESUMEN TIPO DE GASTO Y PARTIDA EP-08</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jAcEg37veEcOPDK9yJfSTbDNHKi9HXr-/view?usp=sharing">ISR POR ENAJENACION DE BIENES EP-5848</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/15ZSSKm2ZxEOm1IChgT1A2tsXYgjKG3T8/view?usp=sharing">OTROS RECURSOS - FISCALIZACIÓN EP-5845</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1MtaelCVnABRowz5jizUDhYFeCmJeCIUR/view?usp=sharing">PROGRAMAS SUJETOS A REGLAS DE OPERACIÓN EP-5931</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            
            
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
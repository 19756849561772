<template>
    <section class="otros_enlaces container mt-3 mb-4">
       <p class="title1_vino">Otros enlaces</p>
        <div class="row mt-3 title_secciones">
            <div class="col-12 col-md-4">
                <div class="btn d-flex flex-column align-items-center my-5" :class="{'activo' : notasInformativas == true}" @click="notasInformativas = true, videosRelevantes = false, revistaDigital = false">
                    <i class="fa-regular fa-newspaper iconos" :class="{'activo' : notasInformativas == true}"></i>
                    <span class="txt_pie_icono">NOTAS INFORMATIVAS</span>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="btn d-flex flex-column align-items-center my-5" :class="{'activo' : videosRelevantes == true}" @click="notasInformativas = false, videosRelevantes = true, revistaDigital = false">
                    <i class="fa-solid fa-tv iconos" :class="{'activo' : videosRelevantes == true}"></i>
                    <span class="txt_pie_icono">VIDEOS RELEVANTES</span>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="btn d-flex flex-column align-items-center my-5" :class="{'activo' : revistaDigital == true}" @click="notasInformativas = false, videosRelevantes = false, revistaDigital = true">
                    <i class="fa-solid fa-video iconos" :class="{'activo' : revistaDigital == true}"></i>
                    <span class="txt_pie_icono">REVISTA DIGITAL</span>
                </div>
            </div>
        </div>
        <!-- Notas informativas -->
        <div v-if="notasInformativas" class="row mt-3">
            <!-- Info dinamica -->
            <!-- {{notasInformativasData[0]}} -->
            <div v-for="(nota, x) in notasInformativasData" :key="x" class="col-xs-12 col-sm-6 col-md-4">
                <!-- <img class="img-fluid img-fluid_" src="@/assets/images/prueba.jpg" style="border-radius:6px;"> -->
                <img class="img-fluid img-fluid_" :src="nota.urlImgNota" style="border-radius:6px;">
                <div class="py-3" style="height:5rem">
                    <span class="txt-vino fw-semibold">{{nota.tituloNotas}}</span>
                    <p class="txt-mini mb-0">{{nota.fechaTitulo}}</p>
                </div>
                <p class="txt-gris-strong mt-3">{{nota.contenidoNota}}</p>
            </div>
        </div>
        <!-- Videos relevantes -->
        <div v-if="videosRelevantes" class="row mt-3">
            <div class="col-12 col-md-4">
                <iframe 
                    width="300" 
                    height="315" 
                    src="https://www.youtube.com/embed/BYphiBUrjMQ" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                </iframe>
            </div>
            <div class="col-12 col-md-4">
                <iframe 
                    width="300" 
                    height="315" 
                    src="https://www.youtube.com/embed/oJYCclXdEwU" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                </iframe>
            </div>
            <div class="col-12 col-md-4">
                <iframe
                    width="300" 
                    height="315" 
                    src="https://www.youtube.com/embed/i1EQCapg6I0" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                </iframe>
            </div>
        </div>
        <!-- Revista digital -->
        <div v-if="revistaDigital" class="row mt-3">
            <div class="col-12 col-md-6 d-flex justify-content-center">
                <iframe 
                    width="400px" 
                    height="700px" 
                    src="https://www.yumpu.com/es/embed/view/E4ZUdcwyODpg0oQx" 
                    frameborder="0" 
                    allowfullscreen="false"  
                    allowtransparency="true">
                </iframe>
            </div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
                <iframe 
                    width="400px" 
                    height="700px" 
                    src="https://www.yumpu.com/es/embed/view/BC2m3t7vIUd8epDP" 
                    frameborder="0" 
                    allowfullscreen="true" 
                    allowtransparency="true">
                </iframe>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            notasInformativas: true,
            videosRelevantes: false,
            revistaDigital: false,
            notasInformativasData: [],
            urlJson: 'https://inesachiapas.github.io/servicioINESA/notasInformativas.json', //url en donde estara montado el json
        }
    },
    created(){
        this.getNotasInformativas();
    },
    methods:{
        async getNotasInformativas(){
            try {
                const response = await axios.get(this.urlJson); 
                this.notasInformativasData = response.data;
                console.log(this.notasInformativasData);
            } catch (error) {
                console.error(error);
            }
        }
    }
}
</script>
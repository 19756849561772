<template>
    <section class="encuesta">
        <div class="container my-3">
            <div class="d-flex justify-content-between">
                <p class="title3_vino">Encuestas</p>
                <div class="p-2 bd-highlight">
                    <router-link class="btn-agua" :to="{name: 'home'}"><i class="fa fa-arrow-left"></i> Atras</router-link>
                </div>
            </div>
            <div class="d-flex justify-content-center mb-4">
                <div class="bg-gris px-1 py-2">
                    <p class="titulo_encuesta txt-mini mb-0">ENCUESTAS O FORMULARIOS INESA</p>
                    <p class="txt-mini mb-0">SOLO ESTARA DISPONIBLE DEL 04/10/2023 AL 06/10/2023</p>
                </div>
            </div>
            <div class="d-flex justify-content-center px-1">
                <iframe 
                    src="https://docs.google.com/forms/d/e/1FAIpQLSew8_gMBlEPZtMy_jdz9-rLXP3bCRE-POVwV7wbX3qGngZ-pQ/viewform" 
                    width="800" 
                    height="700" 
                    frameborder="0" 
                    marginheight="0" 
                    marginwidth="0">Cargando…
                </iframe>
            </div>
            
        </div>
    </section>
</template>
<script>
export default {
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>
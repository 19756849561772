<template>
  <div class="home">
    <!-- Aqui se inserta los componentes que tendra la página -->
    <slide></slide>
    <mensaje-insituto></mensaje-insituto>
    <programas></programas>
    <otros-enlaces></otros-enlaces>
    <obligaciones-transparencia></obligaciones-transparencia>
  </div>
</template>

<script>
import slide from '@/views/home/Slide.vue';
import MensajeInsituto from '@/views/home/Mensaje-instituto.vue';
import Programas from '@/views/home/Programas.vue';
import ObligacionesTransparencia from '@/views/home/Obligaciones-transparencia.vue'
import OtrosEnlaces from '@/views/home/Otros-enlaces.vue'
export default {
  name: 'Home',
  components: {slide, MensajeInsituto, Programas, ObligacionesTransparencia, OtrosEnlaces},
}
</script>

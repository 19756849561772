<template>
    <section class="encuesta">
        <div class="container my-3">
            <div class="d-flex justify-content-between">
                <p class="title3_vino">CONVOCATORIA DE DIBUJO 2024</p>
                <div class="p-2 bd-highlight">
                    <router-link class="btn-agua" :to="{name: 'home'}"><i class="fa fa-arrow-left"></i> Atras</router-link>
                </div>
            </div>
           
              
            </div>
            
                <div class="d-flex justify-content-center px-1">
            <div class="img-container">
                <link rel="stylesheet" href="image.css"> 
<img src="/img/cuencas/CONCURSO DE DIBUJO.jpeg" 
            />
        </div>
    
            
        </div>
    </section>
</template>
<script>
export default {
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>
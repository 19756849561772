<template>
  <div class="container mt-3">
    <div class="col-md-12 mt-3 mb-3 pe-5" style="text-align: right">
      <a href="/armonizacionContable" class="btn-agua"
        ><i class="fas fa-arrow-left"></i> Atras</a
      >
      <a href="/" class="ms-2 btn-agua"><i class="fas fa-house"></i> Inicio</a>
    </div>
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <p class="title1_vinolg">
          NORMAS EMITIDAS POR EL CONAC
        </p>
      </div>
    </div>

    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table mb-2">INSTITUTO ESTATAL DEL AGUA</p>
        <p class="mb-2">
         
        </p>


        <p class="formato-table">Formato por Año 2024</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas241T'}"
                  >PRIMER TRIMESTRE</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas242T'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas243T'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas244T'}"
                  ></router-link
                >
              </td>
            </tr>

            
          </tbody>
        </table>
      </div>
    </div>


    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table mb-2">INSTITUTO ESTATAL DEL AGUA</p>
        <p class="mb-2">
         
        </p>


        <p class="formato-table">Formato por Año 2023</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas231T'}"
                  >PRIMER TRIMESTRE</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas232T'}"
                  >SEGUNDO TRIMESTRE</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas233T'}"
                  >TERCER TRIMESTRE</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normas234T'}"
                  >CUARTO TRIMESTRE</router-link
                >
              </td>
            </tr>

            
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2022</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022ene'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022feb'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022mar'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinanciers2022abr'}"
                  ></router-link
                >
              </td>
            </tr>

            
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2021</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'disiplina231T'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021feb'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021mar'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  ></a
                >
              </td>
            </tr>

           
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2020</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2020ene'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2020feb'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  ></a
                >
              </td>
            </tr>
           
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2019</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  ></router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  ></a
                >
              </td>
            </tr>

           
          </tbody>
        </table>
      </div>
    </div>
        
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: '/Ley-de-disiplina'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">PRIMER TRIMESTRE</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/15OuIz6Q6ZCnnb5L-bU8VvmdWlrTzNx8S/view?usp=sharing">Formato 6.- Montos Pagados por Ayudas y Subsidios</a>
                                <li class="d-none">2023</li>
                                
                   
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1VL4vISepNkzVXxYcRNcEQnVetRR9AMWA/view?usp=sharing">Formato 13.- Ejercicio y Destino del Gasto Federalizado y Reintegros</a>
                                <li class="d-none">2023</li> <li></li>

                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
            
            
          
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
<template>
    <section class="encuesta">
        <div class="container my-3">
            <div class="d-flex justify-content-between">
                <p class="title3_vino">INSCRIPCION CARRERA</p>
                <div class="p-2 bd-highlight">
                    <router-link class="btn-agua" :to="{name: 'home'}"><i class="fa fa-arrow-left"></i> Atras</router-link>
                </div>
            </div>
            

            <img src="/img/imagen pagina.png" />
            


           
            
            
        </div>
    </section>
</template>
<script>
export default {
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>


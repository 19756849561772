var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-3"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_vm._m(2),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Base")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Invitación restringida a cuando menos tres personas")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Junta de Aclaraciones")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Apertura de Proposiciones Técnicas y Económicas")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf',"target":"_blank"}},[_vm._v("Dictamen Técnico, Administrativo, Lega y Financiero")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.4%20.-%20Nombramiento_Responsable_Arch_de_Concentración_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Fallo")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_vm._m(3),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Base")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Invitación restringida a cuando menos tres personas")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Junta de Aclaraciones")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Apertura de Proposiciones Técnicas y Económicas")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_vm._m(4),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Base")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Invitación restringida a cuando menos tres personas")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Junta de Aclaraciones")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Apertura de Proposiciones Técnicas y Económicas")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf',"target":"_blank"}},[_vm._v("Dictamen Técnico, Administrativo, Lega y Financiero")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.4%20.-%20Nombramiento_Responsable_Arch_de_Concentración_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Fallo")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_vm._m(5),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Base")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Invitación restringida a cuando menos tres personas")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Junta de Aclaraciones")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Apertura de Proposiciones Técnicas y Económicas")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf',"target":"_blank"}},[_vm._v("Dictamen Técnico, Administrativo, Lega y Financiero")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.4%20.-%20Nombramiento_Responsable_Arch_de_Concentración_2023.pdf',"target":"_blank"}},[_vm._v("Acta de Fallo")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3 mb-3 pe-5",staticStyle:{"text-align":"right"}},[_c('a',{staticClass:"btn-agua",attrs:{"href":"/armonizacionContable"}},[_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" Atras")]),_c('a',{staticClass:"ms-2 btn-agua",attrs:{"href":"/"}},[_c('i',{staticClass:"fas fa-house"}),_vm._v(" Inicio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex bd-highlight"},[_c('div',{staticClass:"p-2 flex-grow-1 bd-highlight"},[_c('p',{staticClass:"title1_vino"},[_vm._v(" Licitaciones 2017 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Licitación No. IR-20750-001-2017")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Licitación No. IR-20750-001-2017")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Licitación No. IR-20750-003-2017")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Licitación No. IR-20750-004-2017")])])])])
}]

export { render, staticRenderFns }
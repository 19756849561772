<template>
  <div id="app">
    <!-- Aqui navs, header y footer -->
    <nav-gob></nav-gob>
    <nav-instituto></nav-instituto>
    <router-view/>
    <footer-instituto></footer-instituto>
    <toeInstituto></toeInstituto>
  </div>
</template>
<script>
import navGob from '@/views/Nav-gob.vue';
import navInstituto from '@/views/Nav-instituto.vue';
import footerInstituto from '@/views/Footer.vue';
import toeInstituto from '@/views/Toe.vue';
export default {
  components:{navGob, navInstituto, footerInstituto,toeInstituto},
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">Cierre Mensual</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1weP9Fmuzm6TtKyygz3KBSqad35oZ86ex/view?usp=sharing">APLICACION Y-DISPONIBILIDAD-DEL-PRESUPUESTO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11-oSTXO-fSQdyMfS7BywK01ltCRLiLqq/view?usp=sharing">BALANCE PRESUPUESTARIO</a>
                                <li class="d-none">2022</li> <li></li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1R-bQYpMAVFAAxYPoB88pEe4I5uKeG5KT/view?usp=sharing">BALANZA COMPROBACION ACUMULADA </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1NqXwapUraU5OwEFqLXtW3ajmVm1TPh9V/view?usp=sharing">BALANZA DE COMPROBACION MENSUAL </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Nfk8c00l6-VljbgNTYPNbToeIj6Jdp-6/view?usp=sharing">ENDEUDAMIENTO NETO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Kv-iESlfyHn4BJikexpWQK8B3vyrcayY/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1pR8I9lFCoJOX-CXx9f6kGgqyEw7ATniL/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DEL EJERCICIO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Fh2eT5IcVaEQnllJqNcw_xrHykAulYGp/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cqpwAqrm3ZBLYnomNvkjIvKA5emfpE0x/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1zSsKqtLtAXYQS51w5S0OyIXEr3lOqCPD/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1gOgX_FfvGPNHBTApYhpcVy7rjYfvlTXt/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cbStZrLY9V6y_mcRLe_hlktUvtgUDxRX/view?usp=sharing">ESTADO ANALITICO DE LA DEUDA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1FjeZ4NfY7E27zJD3l0Q98XPGxiVoVkhx/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1JP2y8eS6xZWUtEeaikMCYZNjLS4s97Zs/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1mMkZKna-WjM0j7M8-WEjU3u5bGzAjlJr/view?usp=sharing">ESTADO DE ACTIVIDADES POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rBt5wx8Cb4n4IenbSrqL0NLvx-fOiV8T/view?usp=sharing">ESTADO DE ACTIVIDADES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fQkdrqwES7dh5e6n8di-gO53l9Djd9Za/view?usp=sharing">ESTADO DE CAMBIOS EN LA SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1bV52xww9_x_2mCZ_Yzj_TUiBo5C4wakS/view?usp=sharing">ESTADO DE FLUJOS DE EFECTIVO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rJb_Xw1Bd2rNP3ruSIzkPVWzPRuY3685/view?usp=sharing">ESTADO DE SITUACION FINANCIERA DETALLADO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1_kEIEloIIB8eqZeK5r9OUbI3UIO3jV2v/view?usp=sharing">ESTADO DE SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/18PDCjkK0AT74ZZdWvfLijOx3GRi85vHD/view?usp=sharing">ESTADO DE SITUACION FINANCIERA POR RUBROS </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1nCHeDsj_ZO0AOpmK_daviprWgFIa1za_/view?usp=sharing">ESTADO DE VARIACION EN HACIENDA PUBLICA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1MmO09e8Vam2aWgaEbGwasgRdJc6Vnk_A/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DE AÑOS ANTERIORES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1yyvT6oIAn7jOteEvloEqKvVuoZXnQD6L/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jPqpurFb9kpnKG7qtH7pReBEFfkPsmbG/view?usp=sharing">INDICADORES DE POSTURA FISCAL</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1E36SYcqTxIUp-By_HK5QtBm42UDG-32j/view?usp=sharing">INFORME ANALITICO DE OBLIGACIONES DIFERENTES DE FINANCIAMIENTOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1sxIGzFSbsKAkLzmULKfIWG7MnG9EJSoW/view?usp=sharing">INFORME DE LA DEUDA PUBLICA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1O6_HYM-QgEKcSX-r-ZubqoYBVcAUAhzH/view?usp=sharing">INFORME SOBRE PASIVOS CONTIGENTES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1GGqgHT_Mmw4K9w4BoK5apq36qGBDyO1-/view?usp=sharing">INTEGRACION DEL PRESUPUESTO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Sd18y718YFyEwOVeKO6FHjC3plhuIAUd/view?usp=sharing">INTERESES DE LA DEUDA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1S-oI6izJJVJ-e5G1O1ax2ZHgGT0jYfC8/view?usp=sharing">NOTAS DE MEMORIA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/12PHGJOBeK8BAsO9dN9YGTayNAoG-NgXh/view?usp=sharing">RELACION DE CUENTAS BANCARIAS PRODUCTIVAS ESPECIFICAS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10tmGKQbzeEm7bsHCoY3JHEXXelq32S34/view?usp=sharing">RELACION DE ESQUEMAS BURSATILES Y COBERTURA FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
<template>
<header id="app">
  <nav class="bg-navbar">
      <div class="logo">
        <router-link to="/"><img src="@/assets/images/logo-agua.png" alt="Logo"></router-link>
      </div>
      <button class="dropdown-button" @click="toggleMenu">&#9776;</button>
      <ul class="menu" :class="{ 'open': isMenuOpen }">
        <li><router-link class="nav-link active" to="/conocenos">Conócenos</router-link></li>
        <li><router-link class="nav-link" to="/programas">Programas</router-link></li>
        <li><a href="http://iea.transparencia.chiapas.gob.mx/" target="_blank" class="nav-link" to="/legislacion/municipal">Portal de transparencia</a></li>
        <li><router-link class="nav-link" to="/armonizacionContable">Armonización Contable</router-link></li>
        <li><router-link class="nav-link" to="/bibliotecaVirtual">Biblioteca</router-link></li>
        <li><router-link class="nav-link" to="/avisoDePrivacidad">Aviso de privacidad</router-link></li>
      </ul>
    </nav>
</header>
</template>
<script>
export default {
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
};
</script>
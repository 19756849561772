var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"revista mt-3"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"title3_vinolg"},[_vm._v("Revista Institucional")]),_c('div',{staticClass:"p-2 bd-highlight"},[_c('router-link',{staticClass:"btn-agua",attrs:{"to":{name: 'home'}}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" Atras")])],1)]),_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"bg-gris container_ediciones"},[_c('div',{staticClass:"container pt-5"},[_c('p',{staticClass:"title1_vino"},[_vm._v("Ediciones posteriores")]),_c('div',{staticClass:"row mt-5 px-5 py-5"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_vm._m(2),_c('router-link',{staticClass:"ruta",attrs:{"to":{name: 'revista_2edicion'}}},[_c('h6',{staticClass:"txt-vino ms-3 fw-bold text-center"},[_vm._v("2 EDICIÓN")])])],1)]),_vm._m(3),_vm._m(4)]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('iframe',{attrs:{"width":"800px","height":"700px","src":"https://www.yumpu.com/es/embed/view/E4ZUdcwyODpg0oQx","frameborder":"0","allowfullscreen":"true","allowtransparency":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"links_revista"},[_c('a',{staticClass:"txt-vino txt-mini fw-bold",attrs:{"href":""}},[_c('i',{staticClass:"fa-regular fa-folder carpeta_mini me-3 ms-4"}),_vm._v(" Descargar Revista en Pdf (Calidad Alta)")]),_c('a',{staticClass:"txt-vino txt-mini mx-3 fw-bold",attrs:{"href":""}},[_vm._v(" Descargar Revista en Pdf (Calidad Media)")]),_c('a',{staticClass:"txt-vino txt-mini fw-bold",attrs:{"href":""}},[_vm._v(" Descargar Revista en Pdf (Calidad Baja)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-icono-carpeta"},[_c('i',{staticClass:"fa-regular fa-folder carpeta"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('div',{staticClass:"container-icono-carpeta"},[_c('i',{staticClass:"fa-regular fa-folder carpeta"})]),_c('h6',{staticClass:"txt-vino ms-3 fw-bold text-center"},[_vm._v("PROXIMAMENTE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('div',{staticClass:"container-icono-carpeta"},[_c('i',{staticClass:"fa-regular fa-folder carpeta"})]),_c('h6',{staticClass:"txt-vino ms-3 fw-bold text-center"},[_vm._v("PROXIMAMENTE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row px-5 py-4"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('div',{staticClass:"container-icono-carpeta"},[_c('i',{staticClass:"fa-regular fa-folder carpeta"})]),_c('h6',{staticClass:"txt-vino ms-3 fw-bold text-center"},[_vm._v("PROXIMAMENTE")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('div',{staticClass:"container-icono-carpeta"},[_c('i',{staticClass:"fa-regular fa-folder carpeta"})]),_c('h6',{staticClass:"txt-vino ms-3 fw-bold text-center"},[_vm._v("PROXIMAMENTE")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('div',{staticClass:"container-icono-carpeta"},[_c('i',{staticClass:"fa-regular fa-folder carpeta"})]),_c('h6',{staticClass:"txt-vino ms-3 fw-bold text-center"},[_vm._v("PROXIMAMENTE")])])])])
}]

export { render, staticRenderFns }
<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">CUENTA PUBLICA FINANCIERA</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vrvpWm44GBgOkREcNqmYB0kfAapIa1mv/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
            
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1OTMR3LnE1aj9ANLxLdd8Pbz3CiBgIhwb/view?usp=sharing">ENDEUDAMIENTO NETO</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1HI5RgOKAIBnQRK7nBOr9po-jn-S6C-zr/view?usp=sharing" class="link">INTERESES DE LA DEUDA</a></li>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10WV_QiTFuhxmSIzpGTGa7Ibe4Y6_DcpX/view?usp=sharing">Notas Estados Financieros</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/14mSLLgrH7v2u_sFnZLvnwbA9r3z1Jqmn/view?usp=sharing">Edo-Analitico-Ingresos-Rec-años-anteriores</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                         <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rEpvz2nl95vnFZFZMzpv30NRa5R0W8px/view?usp=sharing">Edo-Analitico-Ingresos-Rec-Ejercicio</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ED_IuFV70e6YHk-BR5yG3e6d4yjT99ZK/view?usp=sharing">Edo-Analitico-Deuda-Otros-Pasivs</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jpXUPLyTEiVvMy4q9okcwP1NXKGNIoox/view?usp=sharing">Edo-Analitico-Activo</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11D8RLfTMWQ4JFQPMur1ASkSGCRDEnN0n/view?usp=sharing">Edo-Actividades</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1hFtID8wwE2ztKqLwnbLRzZSNju8WeUVz/view?usp=sharing">Edo-Cambios-Situacion-Financ</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1M2_n8aJIkfKntH800IXYMdIJFvUBi8Hz/view?usp=sharing">Edo-Flujos-Efectivo</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
                <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1xCwJaJJjLjdBq_k9f37Nn2UX53Ygm4jZ/view?usp=sharing">Edo-Situacion-financiera-por cuenta</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11Fw4MyaLXxvU9yiucUgweGPz2O0KMabZ/view?usp=sharing">Edo-Situacion-financiera-por rubro</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1txXob0Bw9LOkwiTzKwMCdHDy5ucC_PPM/view?usp=sharing">Edo-Variacion-Hda-Publica</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1J3nSlWZijKfDC7GzF1mGcpRzoGa-JMOz/view?usp=sharing">Indicadores-Postura-Fiscal-Rec-Años-Anteriores</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1p5Udrj8gIQiF_iadhqdq7hQN6lTJ0HKE/view?usp=sharing">Indicadores-Postura-Fiscal-Rec-Ejercicio</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1qSjUVNfPpf-blr6iFTaFdT6qcv7gkYJM/view?usp=sharing">Indicadores-Postura-Fiscal</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1viyE1-mwRBFkiwYovEA2q9IeJVMl-5uB/view?usp=sharing">Informe-Pasivos-Contingentes</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1k7ikgvEY7y8tNB6tmjqiX345ZlKYnVoe/view?usp=sharing">Rel-Activos-Intangibles-Patrimonio</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/12ek5qY9szSPpbbmGzl4d5JipxIejUJRH/view?usp=sharing">Rel-Bienes-Inmuebles-Patrimonio</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1coA43KcHXgx3i-yFkboEhJhULdaZgP7m/view?usp=sharing">Rel-Bienes-Muebles-Patrimonio</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1bLfT_XFXncSP5PeWVieI-ARDGHR6AsfX/view?usp=sharing">Rel-Esquemas-Bursatiles-Cobert-Financ</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16M1ES5by4Phsvzc1LIx7mfxXADCxUMSZ/view?usp=sharing">LDF-1-Edo-Situac-financiera-detallado</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1pSyFABIfAs1EXjGgy3krJBkyXHBUDg6Y/view?usp=sharing">LDF-2-Informe-Deuda-otros-Pasivos</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1p8IEc79gUDSv_VySZMdCRzgCCIU5Zxgw/view?usp=sharing">LDF-3-Oblig-Diferentes-Financiammtos</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1tZOX-o4FLjf4AU8Es3vRgJbFCjXjck4h/view?usp=sharing">LDF-4-Balance-Presupuestario</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1MLZh-z56pTOAlw1iuYN3Y_u8U0TWvTgT/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1auNccVm6mnBcLt-Nvqwf-uiYcuouXKz2/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Años-Ant</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vsINutIa70GaAfHvGpFABhbvuOa3YxIA/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Ejercicio</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rfCe1ChF_RDdfnlTRtlRFMyUZGhM4TYu/view?usp=sharing">LDF-Articulo-40</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1TNe72Ehm0qLSm7Yy_8elSgm-0paL66zl/view?usp=sharing">LDF-Articulo-25</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/17iH90dZ55KH2m3_sDko5Qj9KUyaxykQ4/view?usp=sharing">LDF-Articulo-31</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1obmd9lU9A5Furyg3bTrNXBWdRKbVhxVK/view?usp=sharing">Analisis-Adqu-Bienes-Inmuebles-Muebles-Intangibles</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1pEAsoNa6MAgR84QtDLUhGGUbsPId0fVI/view?usp=sharing">Analisis-Antigüedad-Saldos-Ctas-Balance</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/14WrozCyp823vt0ABGn7yOrGXvZgauNtA/view?usp=sharing">Hoja-Trabajo</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1RoeW3eYxGCc-sW2hvSSlT1hZXrzHY5Nm/view?usp=sharing">Integracion-Activo-libre-Disp-Etiquetado</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1wWEAjXEt6s1wogGBU84dpO0cnSyrted1/view?usp=sharing">Integracion-Activo-Ramo</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1wXMlDTM2PO7w34S1EwoGoTwH3PTe_3IE/view?usp=sharing">Integracion-Pasivo-libre-Dispos-Etiquetado</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1F3QOixajCzZFt0iPmz0neTcOnSJTT3US/view?usp=sharing">Integracion-Pasivo-Ramo</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1KO886l1tt3OBA_TDNbKI54Tavvdb35A_/view?usp=sharing">Integracion-Ingresos-Beneficios-Gtos-Perdidas</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/14w7tacdZKjA3wgWo1kqvIZFVBFAxWJmL/view?usp=sharing">Justific-Integracion-Ingresos-Benef-Gtos-Perdidas</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            
            
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PRESUPUESTARIOS</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1faLFBJhy_io_1UaJk7he34qB_gc4nGsg/view?usp=sharing">ESTADO-ANALITICO-DE-INGRESOS</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
            
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/13Rx-S9Sp8vdqVaiazv7GgKEgOC9Wlgx9/view?usp=sharing">ENDEUDAMIENTO-NETO</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1IcvkgUkYq-Kf92iap3DYwymqgYouicOw/view?usp=sharing" class="link">INTERESES-DE-LA-DEUDA</a></li>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Ols9AtQNcBtd1tNXx5rlUNyxoJYdJMq5/view?usp=sharing">RPA-1-Edo-Analit-Clasif-Objt-Gto</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1OkIvFP3ZVdDrQU7dBh1f9S6hHArCaldZ/view?usp=sharing">RPA-2-Edo-Analit-Clasif-Economica</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                         <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1maVL0ZTzs8vmrOpBLVRL5v1x2oW26YE4/view?usp=sharing">RPA-3-Edo-Analit-Clasif-Admitiva-Org</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1reIxk3xlpfiTbq03SnZxdRRuIBLSG4Zd/view?usp=sharing">RPA-4-Edo-Analit-Clasif-Admitiva-pod</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1hTHzccglbCnbaI-9ubrAhBYMaaJPB8vK/view?usp=sharing">RPA-5-Edo-Analit-Clasif-Admitiva-ent</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16BX7RKPSYsamGkS1HP-dpQ5D75NVqYFH/view?usp=sharing">RPA-6-Edo-Analit-Clasif-funcional</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PROGRAMATICOS</p>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1aY58kItdpvav5LVgRmPmhj1WZRYLERcf/view?usp=sharing">PROGRAMAS PROYECTOS INVERSION</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fF9VcBDHErb92McqJJZtu6xcyeQeL2fk/view?usp=sharing">CATEGORIA PROGRAMATICA</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">REINTEGROS FEDERALIZADOS</p>
                    </div>
                </div>
                <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1m1TwZ72LVCQuLjywu9_3mroX3E3QaYlF/view?usp=sharing">FORMATO FEDERALIZADO REINTEGROS</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION LEY DISCIPLINA FINANCIERA</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/144cSAFjsXICcSEUFZEGzoXyBiqjz6PUK/view?usp=sharing">LDF-6a-Edo-Analit-Detallado-Clasif-Obj-Gto</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1XoEN04OD-ga33ThnSh5Vqus3eA25jfbF/view?usp=sharing">LDF-6b-Edo-Analit-Detallado-Clasif-Admitiva</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1X7zda61vh_IwPPqvSsFZOqy6x4NbFjSk/view?usp=sharing">LDF-6c-Edo-Analit-Detallado-Clasif-funcional</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1dv6iYUOcChKmhv4_gqRIEUmMPCF3GXxI/view?usp=sharing">LDF-6d-Edo-Analit-Detallado-Clasif-Serv-pers</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1reB6HKl71ickLSoxsBErE_d-PAEzLvP4/view?usp=sharing">GUÍA CUMLPLIMIENTO LDF ENTIDADES FEDERATIVAS Y MPIOS</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION DE INTEGRACION Y ANALISIS</p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1y4fZFFVMiwalxbDFfcKViC_3HZALZkk_/view?usp=sharing">FONDO DE FISCALIZACIÓN Y RECAUDACIÓN EP-5814</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1A44um1ffw2vWqO5wfAGyOSWReSS7dTP6/view?usp=sharing">Analisis-Recursos-Federales-Etiquetados</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Xp1h5m6I0Q71pY6dQSssLzXTqdgPG2-J/view?usp=sharing">ANÁLISIS PRINCIPALES VARIACIONES Y ADECUACIONES AL PRESUPUES</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1mDdX8phWPdpkun5_427VtnkWi29gAom4/view?usp=sharing">ANÁLISIS DE LOS PROYECTOS DE GASTO DE CAPITAL MAYOR</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1e4ETHC3EVRZgi9EmxmQhcimR06CvfHLN/view?usp=sharing">Aplicacion-Presupuesto-por-rubros</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1w33xLXXIfREuSPnvS-bvVaLGf4O8eQt1/view?usp=sharing">FONDO GENERAL DE PARTICIPACIONES EP-5811</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1lqFLouaNw4c7UPwVVTXPhrsaxt19Txv_/view?usp=sharing">ANALISIS DE LA RAZÓN DEL AVANCE % DEL PRESUPUESTO DEVENGADO1</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1XukYbb1l_b7lyvEszaB8eXPaQBiPbEfQ/view?usp=sharing">IMPUESTO A LA VENTA FINAL DE GASOLINA Y DIÉSEL EP-5817</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1aynVq5jlvDPCvPawVZnD3bqYhcpE_3xb/view?usp=sharing">Cierre 2021</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EPPfvjc9iMq3H_5pckdrxE6r8r9hVbcB/view?usp=sharing">COMPARATIVO Y ANÁLISIS PRESUP DEVENGADO POR OBJETO DEL GASTO</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1bjvCwN8TFQGZTe6s8fbOsd5m6scziJOQ/view?usp=sharing">ANÁLISIS DE PARTIDAS POR CLASIFICACIÓN ADMITIVA EP-01</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1OOOvqaR9-ayAHqlTBuALs6EREgj6C0Rb/view?usp=sharing">CAPÍTULOS, CONCEPTOS, PARTIDA GENÉRICA ESPECÍFICA EP-03</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/12iLkpwB4K5OHMPY5uiEjYlhj5PsPFl7f/view?usp=sharing">APLICACIÓN DE LOS RECURSOS POR INGRESOS EXCEDENTES</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16Mp9SeFVRcXJ2OLgANzy0R17zxGFA5cu/view?usp=sharing">ISR PARTICIPABLE ESTATAL EP-5818</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1k7IRWfd33004x1aO9OTXPFsUYoB6Z2_Z/view?usp=sharing">RESUMEN TIPO DE GASTO Y PARTIDA EP-08</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1FRr-E9ALHnMxQLZXuW5qWyDr0YpGgPzn/view?usp=sharing">JUSTIFICACION</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1lEPhxXz-y12BJMnIDS5aJYBu3vGSLHAZ/view?usp=sharing">OTROS RECURSOS OTROS INCENTIVOS ECONOMICOS EP-584A</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1lVA49mM2ob3J5b2wxD1Gczr-s1qOelYd/view?usp=sharing">PROGRAMAS SUJETOS A REGLAS DE OPERACIÓN EP-5931</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            
            
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-3"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2022")]),_c('table',{staticClass:"table table-striped"},[_vm._m(4),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2021")]),_c('table',{staticClass:"table table-striped"},[_vm._m(5),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2020")]),_c('table',{staticClass:"table table-striped"},[_vm._m(6),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2019")]),_c('table',{staticClass:"table table-striped"},[_vm._m(7),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2018")]),_c('table',{staticClass:"table table-striped"},[_vm._m(8),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2017")]),_c('table',{staticClass:"table table-striped"},[_vm._m(9),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2016")]),_c('table',{staticClass:"table table-striped"},[_vm._m(10),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2015")]),_c('table',{staticClass:"table table-striped"},[_vm._m(11),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Segundo Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Tercer Trismestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2014")]),_c('table',{staticClass:"table table-striped"},[_vm._m(12),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("Cuarto Trismestre")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3 mb-3 pe-5",staticStyle:{"text-align":"right"}},[_c('a',{staticClass:"btn-agua",attrs:{"href":"/armonizacionContable"}},[_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" Atras")]),_c('a',{staticClass:"ms-2 btn-agua",attrs:{"href":"/"}},[_c('i',{staticClass:"fas fa-house"}),_vm._v(" Inicio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex bd-highlight"},[_c('div',{staticClass:"p-2 flex-grow-1 bd-highlight"},[_c('p',{staticClass:"title1_vino"},[_vm._v(" Indicadores de Resultado ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2024")]),_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])]),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1t7qZKKc-iUT13xh-AwPRYK69mT2kb1a1/view?usp=sharing","target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1dG1pkK21cskqWDo8-CyOm_7HlZzfGoiQ/view?usp=sharing","target":"_blank"}})])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1E0V0zkzW7NlgF2txuzvN23B0nTQj4qHn/view?usp=sharing","target":"_blank"}})])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1ke7e78JItW4P6kK5RE8yxdpmPoAF0Qcm/view?usp=sharing","target":"_blank"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('p',{staticClass:"formato-table"},[_vm._v("Formato por Año 2023")]),_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])]),_c('tbody',[_c('tr'),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1Ur6xWCaALQVfRrgbXGQEWezu1DRbTr-e/view?usp=sharing","target":"_blank"}},[_vm._v("Primer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1dG1pkK21cskqWDo8-CyOm_7HlZzfGoiQ/view?usp=sharing","target":"_blank"}},[_vm._v("Segundo Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1E0V0zkzW7NlgF2txuzvN23B0nTQj4qHn/view?usp=sharing","target":"_blank"}},[_vm._v("Tercer Trimestre")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1ke7e78JItW4P6kK5RE8yxdpmPoAF0Qcm/view?usp=sharing","target":"_blank"}},[_vm._v("Cuarto Trimestre")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Indicadores")])])])])
}]

export { render, staticRenderFns }
<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PRESUPUESTARIOS</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vKrzNipUJFiopnedVh5PgqzyjlUPHyjP/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2024</li>

                            </ul>
                        </div>
                    </div>
                </div>
            
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1e5ePYoVjRgmcTegl_dQ-xKO3vG-c1Uz8/view?usp=sharing">ENDEUDAMIENTO NETO</a>
                                <li class="d-none">2024</li>
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1HI5DiQtDw3a0DWNjoeS6IlcClbuBqdRa/view?usp=sharing" class="link">INTERESES DE LA DEUDA</a></li>
                                <li class="d-none">2024</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1RiOrSR-ozMy9d9sisYRxPfKc_CBvq196/view?usp=sharing">RPA-1-Edo-Analit-Clasif-Objt-Gto</a>
                                <li class="d-none">2024</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/18CiVyA8DO-CHI6l7zzXZlAQcl8DBlcry/view?usp=sharing">RPA-2-Edo-Analit-Clasif-Economica</a>
                                <li class="d-none">2024</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                         <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1SA9cvM3Z8m_6zKYMJ969iBbkGWaHMB4g/view?usp=sharing">RPA-3-Edo-Analit-Clasif-Admitiva-Org</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1WnXU799Vdd7qn4Fkn8cmkUj9hg8Z9EHA/view?usp=sharing">RPA-4-Edo-Analit-Clasif-Admitiva-pod</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1T-t3m0yLpg3y3H_ua_um7us5yaOxc11O/view?usp=sharing">RPA-5-Edo-Analit-Clasif-Admitiva-ent</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/14GsAd3MtVaWGlPxMnwb7lE1FRjuP4oqO/view?usp=sharing">RPA-6-Edo-Analit-Clasif-funcional</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1L3QZlgXbZPv47wt1L5VX12xQDxUnN1Es/view?usp=sharing">RPA-7-Gto-Categoria-programatica</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PROGRAMATICOS</p>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ppNbReK1LCUENiYiLU8Whb3rQnXTleQv/view?usp=sharing">PROGRAMAS PROYECTOS INVERSION</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vg1mQ64By5yjQjWOo4ZPKaVVg-ljXZxp/view?usp=sharing"></a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">REINTEGROS FEDERALIZADOS</p>
                    </div>
                </div>
                <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1n_HgWMsm7o-i7cZLEEK0srY8a99RGRHb/view?usp=sharing">FORMATO FEDERALIZADO REINTEGROS</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION LEY DISCIPLINA FINANCIERA</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1qeyPjSgZA0XzR1cIxMCBU8Y4dMyqWvi7/view?usp=sharing">LDF-6a-Edo-Analit-Detallado-Clasif-Obj-Gto</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cT-YDvnXX34WFR51h9WwGvghCPo41nmv/view?usp=sharing">LDF-6b-Edo-Analit-Detallado-Clasif-Admitiva</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1uy5e37rMOyKK2U7LnKE3yRtMvR1kvJli/view?usp=sharing">LDF-6c-Edo-Analit-Detallado-Clasif-funcional</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1WeMJwGvBpd7wEi1mTKtnk9U899C-u04P/view?usp=sharing">LDF-6d-Edo-Analit-Detallado-Clasif-Serv-pers</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1OeYMewdfWrNfRGvQln0F_HO87uwsOXkK/view?usp=sharing">PasivosContingentes</a>
                                <li class="d-none">2024</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION DE INTEGRACION Y ANALISIS</p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ACmNYD92I_MMPxzC1xmxuiAMOqWDcRyi/view?usp=sharing">Edo-Analitico-Ingresos-Rec-años-anteriores</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1czA26e47cUkK6dXLXCmpDR2QeFropDdB/view?usp=sharing">Edo-Analitico-Ingresos-Rec-Ejercicio</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cCDXWQGWLyCjh8QUw0Zrh2JwxdK4p0lT/view?usp=sharing">Aplicacion-Presupuesto-por-rubros</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Maw75dfhX0JcNYmbLxxIFy6gbd4DO5b8/view?usp=sharing">Aplicac-Presup-Deveng-tipo-Rec-Ramo-Cap-Gto</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1K8UI-WTakY7SJa5WEISkJDLRwluQgkdv/view?usp=sharing">Aplicac-Presup-Deveng-tipo-Rec-Ramo-Subf-Fnmmto</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1AeAKlZO2yFgXOa92imKxqrdjIYHi34Lb/view?usp=sharing">Cierre-marzo-2024</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1PddFNzFUw2soOGLQoA4PZXx46JbOmtKo/view?usp=sharing">Comparativo-Presup-Devng-Obj-Gto</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10ipcv0ejvYCG73snAT9GFcAxWxZ8tdWZ/view?usp=sharing">EP-01-Analit-partidas-Clasif-Admitiva</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11xUNjccSmJ-z8QhNFNJnmp3d0c9vleGZ/view?usp=sharing">EP-03-Capitulo-concepto-partida-generica-y-espcf</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/164qx3eKc75hIBvAC54l3ssL3cn9UuItx/view?usp=sharing">EP-05-Etiquetado-Rec-federales-Clasif-Admitiv</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1s5Wx7p5XUfGApY7qC3B3aGtsHjei-o1k/view?usp=sharing">EP-05-No-etiquetado-Rec-Federales-Clasif-Admi</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1wn1upMFk-8URBpC1fOaXeKMSUxWPzH57/view?usp=sharing">EP-08-Resumen-tipo-Gto-y-partida</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ekjJqdIPE_Wpo-js6Crh1fuaV4tl4pQC/view?usp=sharing">Justificacion-Aplicac-presup-rubros</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1xUgouMTuokGd2wuGcVzHRDCcrMnJb0z0/view?usp=sharing">Presup-Egr-tipo-Recurso-Fuente-Fnmm-Ramo</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1xI5xS6saQ1ZtRx_u6yUw9M-rvlKuiJ6s/view?usp=sharing">Principales-Adecuaciones-al-Presup-Egresos</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1H9qiAucXL_MR9ATLeC7e70TUPOVJG8gO/view?usp=sharing">LDF-4-Balance-Presupuestario</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1MS0Zpzgo6hOHDJZGfx7Loxpe0cm81N94/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Años-Ant</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div> <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1PRncrRm5SGV6F_GhY3xcu3T6GAnORrHI/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Ejercicio</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div> <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rcpGNPzUggKUCTcM99IvDU32Nchgl5Yq/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            
            
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Popper from 'popper.js';
import jQuery from 'jquery';
import "@/assets/css/style.css"
import "@/assets/css/header.css"
import "@/assets/css/footer.css"
import "@/assets/css/obligacionesStyle.css"
import "@/assets/css/programasIndex.css"
import "@/assets/css/responsive.css"
import "@/assets/css/home.css"
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import '@fortawesome/fontawesome-free/css/all.css';

import axios, {isCancel, AxiosError} from 'axios';
console.log(axios.isCancel('something'));

library.add(fas, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);
// Vue.prototype.urlServe  =  'http://189.129.148.104/'   // <--- repositorio de archivos
Vue.prototype.urlServe  =  'https://institutodelagua.chiapas.gob.mx/'   // <--- repositorio de archivos


 
window.$ = jQuery;
window.jQuery = jQuery;
window.Popper = Popper;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">CUENTA PUBLICA FINANCIERA</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1HacpMXidi2YkrJA8sOfy7cTln3OKfGwL/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
            
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16z-Uc2gQ1NP5xCLucsSgP4r28ZE2hLPu/view?usp=sharing">ENDEUDAMIENTO NETO</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1tajm2TCMUeSNVySIWIpM0wGrPptwItkR/view?usp=sharing" class="link">INTERESES DE LA DEUDA</a></li>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ZIsQX4sCFTGt70JrXMOM6_9_lr-9zR0o/view?usp=sharing">Notas Estados Financieros</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1j8zW-I6ZN9Q5CWuCuSsNiIR07b8hmFGV/view?usp=sharing">Edo-Analitico-Ingresos-Rec-años-anteriores</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                         <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ltReWjnkph8wevZE_g0QTaL96-hEfcwR/view?usp=sharing">Edo-Analitico-Ingresos-Rec-Ejercicio</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1JLvuw9h_EcC7YOLdJy4VfC87g8ifAM9F/view?usp=sharing">Edo-Analitico-Deuda-Otros-Pasivs</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1H7ozfcx5N4xa-5lQT0g2tTTnXB9LJgwN/view?usp=sharing">Edo-Analitico-Activo</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1r-4Cbak_1xHIjDtoiymEMettaYQoqbB-/view?usp=sharing">Edo-Actividades</a>
                                <li class="d-none">2021</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1F3ZiF27-Zx1WTl_WyGOcJ5t-kNiimusc/view?usp=sharing">RELACION-DE-CUENTAS-BANCARIAS-RODUCTIVAS-ESPECIFICAS</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1j2Y3kQVYVMrNHxxd6dJupZzq_9jD9_bS/view?usp=sharing">Edo-Cambios-Situacion-Financ</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11DtrHpfav-35e7jvmA4ft0BUYlnLUDLP/view?usp=sharing">Edo-Flujos-Efectivo</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
                <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/13J1lT_jErl-FFEW3sCrCtDYYQlEanbc6/view?usp=sharing">Edo-Situacion-financiera-por cuenta</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rfSSpKda6mhUuUq6nSxfvqw9aoO3QvYf/view?usp=sharing">Edo-Situacion-financiera-por rubro</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1HOEXGXHbZHhdWsj4--JM5spKrm4wNT_i/view?usp=sharing">Edo-Variacion-Hda-Publica</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cyrZTylF2771Xfnf4wP6mOybFEhA2YfC/view?usp=sharing">Indicadores-Postura-Fiscal-Rec-Años-Anteriores</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vp_PMtO-4j2zlca6HCz9fy5WifvbfcRU/view?usp=sharing">Indicadores-Postura-Fiscal-Rec-Ejercicio</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1XJbbsWni8ZOHs20Aac33OXrrp9sm8lke/view?usp=sharing">Indicadores-Postura-Fiscal</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1-NkHOV4rEKX7KxuK84hFjQtNf0GOM10H/view?usp=sharing">Informe-Pasivos-Contingentes</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1NEOQ-_h4YOWe14KbrXTVER63zgoIiwLd/view?usp=sharing">Rel-Activos-Intangibles-Patrimonio</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ikXGFHScB2pr03BWPneijyN_9sT28BjY/view?usp=sharing">Rel-Bienes-Inmuebles-Patrimonio</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EGR6-LffVkaKLutT1UzmfrtzoB7fMIOR/view?usp=sharing">Rel-Bienes-Muebles-Patrimonio</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1yUq3S9_cmBA1kpJx2iE95SPljK4JuMI7/view?usp=sharing">Rel-Esquemas-Bursatiles-Cobert-Financ</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ntaB5qc8hkvYmoZGIUslEpvjU5OMF-iY/view?usp=sharing">LDF-1-Edo-Situac-financiera-detallado</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1kgk6eLxKug9cl2t3ryQG3sNrf85_TW3w/view?usp=sharing">LDF-2-Informe-Deuda-otros-Pasivos</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/135MLLVmDe_xyxpvYvRQIxkWJNQxpJH5s/view?usp=sharing">LDF-3-Oblig-Diferentes-Financiammtos</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EfE4mUqjUvzObikOfP8h2u6JeZAM1No6/view?usp=sharing">LDF-4-Balance-Presupuestario</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1txjJc72Kd8Rae7AJ00DEe9LplxoB-kWx/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1e0ErYAzvNStx4iq0RarXvmKuMhdjd66J/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Años-Ant</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1FRezWCgw9ZfgtL-HnuuqPXfcrAIZkQAc/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Ejercicio</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Aqh5mscdq1gH5kkRHp19NsC95HCWioD2/view?usp=sharing">LDF-Articulo-40</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1GdvZTlMgM0j6ECDULR5pVQFtqE4S1QwV/view?usp=sharing">LDF-Articulo-25</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1GPgqpeFlYP4-HESOuemT7Qx9BkDNS7hh/view?usp=sharing">LDF-Articulo-31</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jfxG8aPj9qpoaWzegNDV8si5p1C9Jf1S/view?usp=sharing">ANÁLISI DE ADQUISICIONES DE BIENES INMUEBLES, MUEBLES E INTANGIBLES</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vogqFCxWCimjlkA2QRve_nxWqWXCKGks/view?usp=sharing">ANÁLISIS DE ANTIGÜEDAD DE SALDOS EN CUENTAS DE BALANCE</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1moqIatPpbhDEuXtNy-RSJwHB3D74iftT/view?usp=sharing">HOJA DE TRABAJO</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cbfbORxSm9O-4AcYSfAjOTEdaXOzRvPx/view?usp=sharing">INTEGRACIÓN DE CTAS DE ACTIVOS LIBRE DISPOSICIÓN Y ETIQUETADOS FUENTE FINANMTO</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Yl5TI6h0zFSxGgizW5cMd9tQQvd4UuWN/view?usp=sharing">INTEGRACIÓN DE CTAS DE PASIVOS LIBRE DISPOSICIÓN Y ETIQUETADOS FUENTE FINANMTO</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11U1QCx0LtCOBuIRzs27lwMMvtC6sz5Tw/view?usp=sharing">INTEGRACIÓN DE INGRESOS, BENEFICIOS, GASTOS Y PÉRDIDAS</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1w8kCPytXwnVqO-hGv8hngh1oLhq0b6zg/view?usp=sharing">JUSTIFICACIÓN DE INGRESOS, BENEFICIOS, GASTOS Y PÉRDIDAS</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1xNgIF5kb_oyTtmnRPCk_n1iPp5v12baz/view?usp=sharing">DESGLOSE DE PASIVO LIBRE DISPOSICIÓN Y ETIQUETADOS</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1bcmCs2kvTXYFwN_qwTy-smW3TaK1-E2b/view?usp=sharing">DESGLOSE DE ACTIVO DE LIBRE DISPOCIÓN Y ETIQUETADOS</a>
                                <li class="d-none">2021</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                
           
                
               
            </div>
            
            
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
<template>
  <div class="container mt-3">
    <div class="col-md-12 mt-3 mb-3 pe-5" style="text-align: right">
      <a href="/armonizacionContable" class="btn-agua"
        ><i class="fas fa-arrow-left"></i> Atras</a
      >
      <a href="/" class="ms-2 btn-agua"><i class="fas fa-house"></i> Inicio</a>
    </div>
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <p class="title1_vinolg">
          Consejo Nacional de Armonización Contable (CONAC)
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table mb-2">¿Qué es CONAC?</p>
        <p class="mb-2">
          Con la finalidad de dar cumplimiento a lo dispuesto en el Titulo
          Quinto de la ley General de Contabilidad Gubernamental (LGCG) que se
          refiere a la Transparencia y Difusión de la Información Financiera y
          con el propósito de integrar la información financiera de acuerdo a
          los formatos publicados por el Consejo Nacional de Armonización
          Contable, a continuación ponemos a su disposición listado de los
          formatos aplicables a esta institución.
        </p>


        <p class="formato-table">Formato por Año 2024</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2024ene'}"
                  >Estados Financieros Enero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2024feb'}"
                  >Estados Financieros Febrero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros202mar'}"
                  >Estados Financieros Marzo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1eAEMHN2WYROJjyYscD6K45AA5bEgtK63/view?usp=sharing"
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 1er. Timestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros223abr'}"
                  >Estados Financieros Abril</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinanciero2023may'}"
                  >Estados Financieros Mayo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros203jun'}"
                  >Estados Financieros Junio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros023jul'}"
                  >Estados Financieros Julio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023go'}"
                  >Estados Financieros Agosto</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023ep'}"
                  >Estados Financieros Septiembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Octubre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Noviembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Diciembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normasemitidasporelconac'}"
                  >Normas emitidas por el conac</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>




    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2023</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023ene'}"
                  >Estados Financieros Enero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023feb'}"
                  >Estados Financieros Febrero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023mar'}"
                  >Estados Financieros Marzo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023abr'}"
                  >Estados Financieros Abril</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023may'}"
                  >Estados Financieros Mayo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023jun'}"
                  >Estados Financieros Junio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023jul'}"
                  >Estados Financieros Julio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023ago'}"
                  >Estados Financieros Agosto</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2023sep'}"
                  >Estados Financieros Septiembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Octubre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Noviembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Diciembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'normasemitidasporelconac'}"
                  >Normas emitidas por el conac</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2022</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022ene'}"
                  >Estados Financieros Enero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022feb'}"
                  >Estados Financieros Febrero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022mar'}"
                  >Estados Financieros Marzo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinanciers2022abr'}"
                  >Formatos 6 de Ayudas y Subsidios</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022abr'}"
                  >Estados Financieros Abril</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022may'}"
                  >Estados Financieros Mayo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022jun'}"
                  >Estados Financieros Junio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022ag'}"
                  >Formatos 6 de Ayudas y Subsidios 2do. Trimestre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022jul'}"
                  >Estados Financieros Julio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022ago'}"
                  >Estados Financieros Agosto</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022sep'}"
                  >Estados Financieros Septiembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://www.institutodelagua.chiapas.gob.mx/docs/2021/Formato%206%20-%203erTRIM2021.pdf"
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 3er. Timestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022oct'}"
                  >Estados Financieros Octubre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022nov'}"
                  >Estados Financieros Noviembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2022dic'}"
                  >Estados Financieros Diciembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Formato 6 de Ayudas y Subsidios 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://www.institutodelagua.chiapas.gob.mx/armonizacion/2022/ley-de-disiplina.html"
                  target="_blank"
                  >Ley de Disiplina Financieras de las Entidades Federativas y
                  los Mpios LDF</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://www.institutodelagua.chiapas.gob.mx/armonizacion/2022/normas-emitidas-conac.html"
                  target="_blank"
                  >Normas Emitidas por el Conac</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2021</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021ene'}"
                  >Estados Financieros Enero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021feb'}"
                  >Estados Financieros Febrero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021mar'}"
                  >Estados Financieros Marzo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021abr'}"
                  >Estados Financieros Abril</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021may'}"
                  >Estados Financieros Mayo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021jun'}"
                  >Estados Financieros Junio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021jul'}"
                  >Estados Financieros Julio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021ago'}"
                  >Estados Financieros Agosto</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021sep'}"
                  >Estados Financieros Septiembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 3er. Timestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021oct'}"
                  >Estados Financieros Octubre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021nov'}"
                  >Estados Financieros Noviembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2021dic'}"
                  >Estados Financieros Diciembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Formato 6 de Ayudas y Subsidios 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Ley de Disiplina Financieras de las Entidades Federativas y
                  los Mpios LDF</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Normas Emitidas por el Conac</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2020</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2020ene'}"
                  >Estados Financieros Enero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros2020feb'}"
                  >Estados Financieros Febrero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Marzo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Abril</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Mayo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Junio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Julio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Agosto</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Septiembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 3er. Timestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Octubre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Noviembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Diciembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Formato 6 de Ayudas y Subsidios</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2019</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Enero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Febrero</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Marzo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Abril</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Mayo</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Junio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Julio</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Agosto</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Septiembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Formatos 6 de Ayudas y Subsidios 3er. Timestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Octubre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Noviembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'estadosFinancieros'}"
                  >Estados Financieros Diciembre</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Formato 6 de Ayudas y Subsidios</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
        
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<template>
  <div>
    <!-- banner -->
    <div class="container-subslide">
      <div class="row">
        <div class="col-md-12">
          <div class="text-slide">
            <h1 class="title">Aviso de Privacidad</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENIDO -->
    <div class="container mt-4 mb-5">
      <div class="row">
        <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                    <a href="/" class="btn-agua"><i class="fas fa-home"></i> Inicio</a>
        </div>
        <div class="col-md-12">
          <div class="title-contend">
            <p class="title1_vino">Aviso de privacidad</p>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Dirección General
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1AV_raiw-YfiP5TLPvsWrAmNcn6QA_j7S/view?usp=sharing">Aviso de privacidad Integral Agenda Ejecutiva<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1psqj2mRs9SNhrKsbr-crPLEVzSSIqyFF/view?usp=sharing">Aviso de privacidad Integral Correspondencia Interna y Externa<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1OZuFK7HjmZdI4S6hXMt5Sox_GCUzFPLu/view?usp=sharing">Aviso de privacidad Simplificado Agenda Ejecutiva<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1PoLXxMY58qbVZ2IpK-U5tvADWz9lGgBO/view?usp=sharing">Aviso de privacidad Simplificado Correspondecia Interna y Externa<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Área de Informática
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1GIhY_vcXtbQ_X_GRCHbUvI_T8KhHv-JR/view?usp=sharing">Aviso de privacidad Integral Actualizacion de Sistemas<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1bGEFB1r76ILjUU9-xk68z1D-0xGtZubp/view?usp=sharing">Aviso de privacidad  Integral Declaracion Patrimonial <i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1FOO0Gr2CtR6TvvyZ5h1jQjjae800G0Vs/view?usp=sharing">Aviso de privacidad  Integral Firma Electronica<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1bvvtKIcjXIlXmKAztOBOY9LBaSo4uipC/view?usp=sharing">Aviso de privacidad  Integral Integracion de Resguardo<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1fclft14iD3ZaNe4jjGQXTmuwHCwexYON/view?usp=sharing">Aviso de privacidad Integral Mantenimiento de Equipos de Computo<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1Pue8MhFuH-7RWptqYCab3109Q8y2H4vw/view?usp=sharing">Aviso de privacidad  Integral Sistema SERAPE<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1bB4SG8LurSVh2M2vuYhwzervEVgdwCsf/view?usp=sharing">Aviso de privacidad Integral Soporte Tecnico<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1NraX32kf1oXW_medf8YvXw8ovvCNyfyB/view?usp=sharing">Aviso de privacidad Simplificado Actualizacion de Sistema<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1dScZQgAtecIGsgmDvimKgWFE6VMvygza/view?usp=sharing">Aviso de privacidad Simplificado Declaracion Patrimonial<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1JsR8fgCuyuBJaD4HyAG2vJkrWoktoQWF/view?usp=sharing">Aviso de privacidad Simplificado Firma Electronica<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1Wu80CTZS9pHJvJzlXNfhC3yzmuWIZlz8/view?usp=sharing">Aviso de privacidad Simplificado Integracion de Resguardo<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/15TSmRQN3OSCrltEgOk7m3jNqJYVCpqdQ/view?usp=sharing">Aviso de privacidad Simplificado Mantenimiento de Equipos<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1G0s6O4yFdaUUHzYgSXbTsMHolX-e7V2v/view?usp=sharing">Aviso de privacidad Simplificado Sistema SERAPE<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/19GyOjnw9_GBPisLWxyNYvsKpWklssZj9/view?usp=sharing">Aviso de privacidad Simplificado Soporte Tecnico<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        
                        
                        
          
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  Área de Recursos Materiales y Servicios Generales
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1bQb4EYH_IONtllo12sduFvMpm5lf3HTh/view?usp=sharing">Aviso de privacidad  Integral Entrega de Materiales<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1qUyMcGjtC1cEobQYUcwDUBpTAORkrHb_/view?usp=sharing">Aviso de privacidad iIntegral Licitaciones<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1ySpgjuDVDeuMnH14MgEY8VRx6k8b5y26/view?usp=sharing">Aviso de privacidad Integral Listas de Asistencia<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1mYTCHWxegN8jlr-0945gCZuQkUHT6OhB/view?usp=sharing">Aviso de privacidad Integral Padron de Proveedores<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1sIiCIfhd8WvqKaDbZPKzP5oGkzS_D_rD/view?usp=sharing">Aviso de privacidad Integral Resguardos Personales<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1sTd7TXv_afI_86-dnjAzWPOWVs5AccTE/view?usp=sharing">Aviso de privacidad Integral Servicios Contratados<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1sodl7roPBmk1K8SPe1xstZ5Q5tUGFWYD/view?usp=sharing">Aviso de privacidad Simplificado Entrega de Materiales<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1CffB7GePm0uQuPz_Vd_riZuP_S-IKwBz/view?usp=sharing">Aviso de privacidad Simplificado Licitaciones<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1cCAsOvKRfWUZeJsV78js_FMylKDtOXhy/view?usp=sharing">Aviso de privacidad Simplificado Lista de Asistencia<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1Y9EOmKSTEA-32JbKahvRAUHbkKHTjIEH/view?usp=sharing">Aviso de privacidad Simplificado Padron de Proveedores<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1y4kfa2KGrxnEzfik8V5fuGcRajIivNUe/view?usp=sharing">Aviso de privacidad Simplificado Resguardos Personales<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1YN3WKNscDfr9RCL94mHuXLrWDSOvzvS6/view?usp=sharing">Aviso de privacidad Simplificado Servicios Contratados<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Área de Recursos Financieros y Contabilidad
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1sSaASVOCvXB59vKTbwam11kErwZC4ZNN/view?usp=sharing">Aviso de privacidad Integral Pago de Comisiones Oficiales<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1T3-Fd2pC4j98KPbN2xMKMbeBXUjMY-V1/view?usp=sharing">Aviso de privacidad Integral Pago de Proveedores y Contratistas<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/14ce1TpD375RK7j1vcLFgVnonlJkUT4N0/view?usp=sharing">Aviso de privacidad Simplificado Pago de Comisiones Oficiales<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1iMOhaf0mmIyeMufwUD7_ZUDkIZ-p6VKC/view?usp=sharing">Aviso de privacidad Simplificado Pago de Proveedores y Contratistas<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Área de Planeacion
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1wAKizmxuA3O11zoQ4GrE2PbZ7Bpkx3Gw/view?usp=sharing">Aviso de privacidad Integral Lista de Asistencia Reuniones<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1BKZRaRVx9D-MXzABc4ciFAhLeLq-NLs7/view?usp=sharing">Aviso de privacidad Integral Solicitudes Atencion Ciudadana<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/12yRtNnCM6TXjKPGOkLTRW09Y7fh9JmH1/view?usp=sharing">Aviso de privacidad Simplificado Lista de Asistencia Reuniones<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/159r0sl7VQkxRYYXVwqcN7vA8ooKYP_ub/view?usp=sharing">Aviso de privacidad  Simplificado Solicitudes Atencion Ciudadana<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  Área de Recursos Humanos
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="">Aviso de privacidad simplificado <i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="">Aviso de privacidad integral <i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                >
                  Dirección de Saneamiento y Calidad del Agua
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1oRqshLLQx91p1c5Y5j1e3MZwXfsWyQwc/view?usp=sharing">Aviso de privacidad Integral Direcctorio Delegados Tecnicos<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1OwzVDm0-GU1IheswiYmlrLaZ2blk2Xx8/view?usp=sharing">Aviso de privacidad Integral Firma de Convenio<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1UxPG6yZzyHjcMpTQWRq3jF4Hl4OKbi8V/view?usp=sharing">Aviso de privacidad Integral Inscripciones a Cursos<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1hbmvrNNnpRi2K6EGuTnsLCmK9t37kJM8/view?usp=sharing">Aviso de privacidad Integral Lista de Asistencia<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1eoEOMB6xpVhxqdwLLqoAdn5FVRErqlOe/view?usp=sharing">Aviso de privacidad Integral Revision y Validacion de Proyectos<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/10VP_j0M8MDaRQ3O3rhKZEsH6Ug5knouu/view?usp=sharing">Aviso de privacidad Integral Solicitud Organismos Operadores<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1WU10qFah3avtDVXLLyZkuVfwlAjIBVZx/view?usp=sharing">Aviso de privacidad Simplificado Directorio Delegados Tecnicos<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1BC8X41RcI_o4UfvwVfwFzu8YHzLYvsgL/view?usp=sharing">Aviso de privacidad Simplificado Firma de Convenio<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/16u1_s01IQInHtOh5HLpb30UNCROuQXET/view?usp=sharing">Aviso de privacidad Simplificado Inscripciones a Cursos<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1OMJQldoX0siYTsL3KTMf_V6lWQtULroQ/view?usp=sharing">Aviso de privacidad Simplificado Lista de Asistencia<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1j65kSvV-v7uW5eL0gcq3fNFMmHWzbjwt/view?usp=sharing">Aviso de privacidad Simplificado Revision y Validacion de Proyectos<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1O_UeUmz4H71fzPHfwmvRtleWcaPPuZ5g/view?usp=sharing">Aviso de privacidad Simplificado Solicitudes Organismos Operadores<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="true"
                  aria-controls="collapseSeven"
                >
                  Dirección de Manejo Integrado de Cuencas y Cultura del Agua
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1yxcag_NUklw5gFsS-EcvN1I7JpMq00FK/view?usp=sharing">Aviso de privacidad Integral Directorio Gerentes de Cuencas<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1sm5uDozNHjzDR6vz-mcw4tG2fTxosBdq/view?usp=sharing">Aviso de privacidad Integral Directorio Responsables de los Espacios de Cultura del Agua<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/11jjVDII7W5e-NBLlUoXh4AtQceCDwNjg/view?usp=sharing">Aviso de privacidad Integral Entrega de Material Didactico<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1eoWES5kvccj7egE3XBoxbCeCAKZmnTba/view?usp=sharing">Aviso de privacidad Integral Firma de Convenio<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/11WcyuEb5VA-j7RINDV6CHLsq8dKcjUXq/view?usp=sharing">Aviso de privacidad Integral Lista de Asistencia Curso-Taller<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1jA1VJmXxejzX7E6dgnZ0XJLrqnQa3rV2/view?usp=sharing">Aviso de privacidad Simplificado Directorio Gerentes de Cuencas<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1a2VsUVC8YeolhwzcIfuFTM9g9k9UQqQg/view?usp=sharing">Aviso de privacidad Simplificado Directorio Responsables de Espacios de Cultura del Agua<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1c4Ol6gOr6NldNbZrdZWUQ_oHO8fIHxlT/view?usp=sharing">Aviso de privacidad Simplificado Entrega de Material Didactico<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1MIiklq7ff7xYDHB-lLLxSWaAtOaR7kuF/view?usp=sharing">Aviso de privacidad Simplificado Firma de Convenio<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1qPcKWHjVrvdzyjr6FSkl4-cU9DXnmHVo/view?usp=sharing">Aviso de privacidad Simplificado Lista de Asistncia Curso-Taller<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div class="accordion mb-2" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button acordion-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  Área de Asuntos Jurídicos
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body ac">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1K6JGAc-GRPqIXUF3PPfEVmQrSVapchW1/view?usp=sharing">Aviso de privacidad Integral Lista de Asistencia Cursos<i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1Iagjt5sst-OreZ2lMakYiuE3I0Ic4x-Q/view?usp=sharing">Aviso de privacidad Integral Representacion Asuntos Jurisdiccionales <i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1bK1Gcrs8vtPARZX3rh16_a1fNOOtWrIG/view?usp=sharing">Aviso de privacidad Integral Solicitudes de Informacion Publica<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1Yow5vAvgFJeUrVYGeQTTs0JxPIV7HvTA/view?usp=sharing">Aviso de privacidad Integral Solicitudes Derechos ARCO<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1GmHiZFlQQbvBBxqyXIMpn9eqtsAc1kIw/view?usp=sharing">Aviso de privacidad Simplificado Lista de Asistencia cursos<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/17KbfcXIp2xWvjiFDG8xXLFT1jvI-0i3c/view?usp=sharing">Aviso de privacidad Simplificado Representacion Asuntos Jurisdiccionales<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1QMyYrmKxuB28gmGXpTuMasCcKoEXmca6/view?usp=sharing">Aviso de privacidad Simplificado Solicitud Derechos ARCO<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1GIhY_vcXtbQ_X_GRCHbUvI_T8KhHv-JR/view?usp=sharing">Aviso de privacidad Simplificado Solicitudes de Informacion Publica<i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loader -->
    <!-- <div class="loader-wrapper">
      <Loader v-if="isLoading"/>
    </div> -->
  </div>
</template>
<script lang="ts">
import Loader from '../Loader.vue';
export default{
  components:{
    Loader,
  },
  data(){
    return{
      isLoading: true,
    };
  },
  mounted()
  {
    setTimeout(() => {
      this.isLoading = false;
    },1000);
  }
}
</script>

<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">CUENTA PUBLICA FINANCIERA</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1oSEYMvY4DKh19KmP_6CWs_NXC9A4cqXe/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
            
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10QnSc93TRPKXdFaes9L2D_0U094EjlCV/view?usp=sharing">ENDEUDAMIENTO NETO</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1qNnLR2TlCsBGCcX_cLJmo2FB8fELVeyr/view?usp=sharing" class="link">INTERESES DE LA DEUDA</a></li>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/15felmdoLn8xD0d1z-cGIibFuYMOw-Nn0/view?usp=sharing">Notas Estados Financieros</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Y7NIGlvmzoxv4wub337i2UHiFFCl0K-G/view?usp=sharing">Edo-Analitico-Ingresos-Rec-años-anteriores</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                         <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1iQRsca3MN3icAz0xs8htwaEToEtPouLk/view?usp=sharing">Edo-Analitico-Ingresos-Rec-Ejercicio</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1HyV3Ksgq5f-k5lBvtNDQrHD1uJOk8Whl/view?usp=sharing">Edo-Analitico-Deuda-Otros-Pasivs</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/14x3txCTvZwbx-sX5_gRXj5oTXKqYRqbw/view?usp=sharing">Edo-Analitico-Activo</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1o9FrBpF5AMB9qaSXJKFoBQKjeSd4xTwb/view?usp=sharing">Edo-Actividades</a>
                                <li class="d-none">2020</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1wwgqONr4UxEBc9CZyONgr4ioYCTGwHsr/view?usp=sharing">Edo-Cambios-Situacion-Financ</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Hjy7pshV_yaz8VrLoTWOL8a8C6VLsjHf/view?usp=sharing">Edo-Flujos-Efectivo</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
                <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1-2OgJWNDR9iyAMYH1YWX8cCZOjnOQeTk/view?usp=sharing">Edo-Situacion-financiera-por cuenta</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1D58RTuAxlRfBkcSF6d-OsbXz1kW0W8bP/view?usp=sharing">Edo-Situacion-financiera-por rubro</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1a9eh88CbBLG4_P73Txlml8HNwGkBY7PZ/view?usp=sharing">Edo-Variacion-Hda-Publica</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Uw4Hut-zxNYcD7FrrhwPpRYyBi0e95qT/view?usp=sharing">Indicadores-Postura-Fiscal-Rec-Años-Anteriores</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1lzlxNnpOo-Wj9avMzf1j6HmancVellcn/view?usp=sharing">Indicadores-Postura-Fiscal-Rec-Ejercicio</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino"></p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1lI0Mnon7xhskKvY2eQgXyrp8imJxl9Ir/view?usp=sharing">Indicadores-Postura-Fiscal</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1F9BkvIz1rIoHUGoE6dQpTbneivBupH6m/view?usp=sharing">Informe-Pasivos-Contingentes</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1LUzVgVf_9VfMZRjuZTQfvvlPOs2G2Qnn/view?usp=sharing">Rel-Activos-Intangibles-Patrimonio</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1PNPoz_Ce6G-acL8rl1BtNsLRIkXEB9p0/view?usp=sharing">Rel-Bienes-Inmuebles-Patrimonio</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11cgHEHu1IcRXwv-rToodjYVxHUPn5t6r/view?usp=sharing">Rel-Bienes-Muebles-Patrimonio</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fMZa4MW3bapHDXnPCCDibdNnX-YudbgO/view?usp=sharing">Rel-Esquemas-Bursatiles-Cobert-Financ</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1NzvWCKgSKGMK4RvYbUM5M_IqnvIxS6lF/view?usp=sharing">LDF-1-Edo-Situac-financiera-detallado</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1F4tfRrWjW0G4w3-cVIfIJ5nHYq9dSsc_/view?usp=sharing">LDF-2-Informe-Deuda-otros-Pasivos</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fWXet5j3bep_qcUEE5f7X2m81wD_OLjD/view?usp=sharing">LDF-3-Oblig-Diferentes-Financiammtos</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1JASuUr-jLfh59xy6CWuncFDlaooD5sjX/view?usp=sharing">LDF-4-Balance-Presupuestario</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1J9tfu9Rhxd9RzQZtVg2fqUueL2FtMfZp/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1W6w0hCS36nx2_byJdPCZLQXp9izgiM3y/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Años-Ant</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1mZJRacq8jajChBo2IbOaay0Z5nYP41Qc/view?usp=sharing">LDF-5-Edo-Analitico-Ingresos-Detallados-Rec-Ejercicio</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1THp1Fc9z9HS2irZIWtu53SXQyVFiIKzl/view?usp=sharing">LDF-Articulo-40</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1znCRu3ueVONRsNSDifBGQZCR6gULtU-N/view?usp=sharing">LDF-Articulo-25</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/19azv5A2JwaIxl_KTD60b5Hh4BetGPYgC/view?usp=sharing">LDF-Articulo-31</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/181eobEpAopnWS-T54UQt37a9lK8YvKBL/view?usp=sharing">ANÁLISI DE ADQUISICIONES DE BIENES INMUEBLES, MUEBLES E INTANGIBLES</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1SPOZqBNn5ZJ3C5btP5vCipTqQh7x690T/view?usp=sharing">ANÁLISIS DE ANTIGÜEDAD DE SALDOS EN CUENTAS DE BALANCE</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1kLJ0MU4CpVdLUzRMBv3FRlgVkWoXWBye/view?usp=sharing">HOJA DE TRABAJO</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1OLG4vIpe1DjHutbqdthgcsBqcN9k2OHM/view?usp=sharing">INTEGRACIÓN DE CTAS DE ACTIVOS LIBRE DISPOSICIÓN Y ETIQUETADOS FUENTE FINANMTO</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ak6Blmjx91RlOYgse7Z6HL1u00CVbEH3/view?usp=sharing">INTEGRACIÓN DE CTAS DE PASIVOS LIBRE DISPOSICIÓN Y ETIQUETADOS FUENTE FINANMTO</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1kkFTFR2iej8oFgI22hHjt77wp34JK2rg/view?usp=sharing">INTEGRACIÓN DE INGRESOS, BENEFICIOS, GASTOS Y PÉRDIDAS</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1JIC2015OKG_pVHk3dRsxgtTk1ymFIz-2/view?usp=sharing">JUSTIFICACIÓN DE INGRESOS, BENEFICIOS, GASTOS Y PÉRDIDAS</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1t4xRrF4vcr0iL1FmQh-LR-_XUagv1kSJ/view?usp=sharing">DESGLOSE DE PASIVO LIBRE DISPOSICIÓN Y ETIQUETADOS</a>
                                <li class="d-none">2020</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jVUUDtUp66m-e95lwzbhE2jXllb3ljgT/view?usp=sharing">DESGLOSE DE ACTIVO DE LIBRE DISPOCIÓN Y ETIQUETADOS</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>

                        
                        
                    </div>
                </div>
                
           
                
               
            </div>
            
            
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
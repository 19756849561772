<template>
  <div class="container mt-3">
    <div class="col-md-12 mt-3 mb-3 pe-5" style="text-align: right">
      <a href="/armonizacionContable" class="btn-agua"
        ><i class="fas fa-arrow-left"></i> Atras</a
      >
      <a href="/" class="ms-2 btn-agua"><i class="fas fa-house"></i> Inicio</a>
    </div>
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <p class="title1_vino">
          Indicadores de Resultado
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2016</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Programa Anual de Adquisiciones, Arrendamientos y Servicios(PAAAS)	</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Documento</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2016</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Programa Anual de Adquisiciones, Arrendamientos y Servicios(PAAAS) Dependencias</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Documento</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2015</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Programa Anual de Adquisiciones, Arrendamientos y Servicios(PAAAS)	</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Documento</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
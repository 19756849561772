<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">Cierre Mensual</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1T1aVzRFOe5d7TUk4T_n_mkKNSxgdafWI/view?usp=sharing">APLICACION Y-DISPONIBILIDAD-DEL-PRESUPUESTO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/13yaz91bGwYHUWjuzBWEm65TKReeqxB-N/view?usp=sharing">BALANCE PRESUPUESTARIO</a>
                                <li class="d-none">2022</li> <li></li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1KmiEmA56bahFqParAphi48o-bLTxjE3w/view?usp=sharing">BALANZA COMPROBACION ACUMULADA </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1O44sCUY4G0t5asGTUS5h2tn7aERZqli5/view?usp=sharing">BALANZA DE COMPROBACION MENSUAL </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1kq4Giy9ZEU-p6wSZ-dKiIRRkJVTQyLaY/view?usp=sharing">ENDEUDAMIENTO NETO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fCUNBLRjU0cT4Kp8cnXwbu-H1cmGYehz/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/133umTZrJIQ4r33KEYHv8ELLGUyOMVD-0/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DEL EJERCICIO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1dIt1iaI05bjYASlO1KiFkNuUU6INFdan/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1gzF7Pu09dO7loAORJt2kpZOfJqk-dSuT/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1J2Va3voitY3zOVKVsh58qZM41E6a-yM1/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ApLSyx-27GL9-W22JMxFggTyVVysu-P7/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1avBIQMZSQo1BG7zN-5DhmaMgY_OSVFRy/view?usp=sharing">ESTADO ANALITICO DE LA DEUDA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16i6naKe1wpPJghv8NthWvfTWgCY1gh32/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jqdqgDIt9iSSN2Cz2KBwKViTfAYqlvZi/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1V-Iyur55pKlLbsWoeqqHXLoyniX_Dd_C/view?usp=sharing">ESTADO DE ACTIVIDADES POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EBsrwbUyzTUz432J2E5uegL6UG2Fy8Pr/view?usp=sharing">ESTADO DE ACTIVIDADES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rJPZnWMvoDyGUI9kALJ9-rbhSxPnR0MD/view?usp=sharing">ESTADO DE CAMBIOS EN LA SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1szEgRiOKDL9V2_M1BG4XsKXvjkX-JiKS/view?usp=sharing">ESTADO DE FLUJOS DE EFECTIVO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1YYT2hTt6bqm8e8SPsyEFMizqhSaWB2I1/view?usp=sharing">ESTADO DE SITUACION FINANCIERA DETALLADO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1wwACFhAt9q0CdLIvkNn-3K-Mh83LKaui/view?usp=sharing">ESTADO DE SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1f5dq8lCrUf2QcCw9mMZ1x1BFCbijGhIt/view?usp=sharing">ESTADO DE SITUACION FINANCIERA POR RUBROS </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1190Iu9X0WmRIyvuDlq__2rJauNtjA5pD/view?usp=sharing">ESTADO DE VARIACION EN HACIENDA PUBLICA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EBx1rSs86zzG_c9YN7F_JFkY5q5uBl-a/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DE AÑOS ANTERIORES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Nebzf4_oykLQpZVe1bfn6j-R5d2oge7k/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1p_TuWL9kDBQnaJT7AiyIxaHQhMommCXB/view?usp=sharing">INDICADORES DE POSTURA FISCAL</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1FNQWSU-kO4Qu4Ytvg5KHlvjOUg05Azpz/view?usp=sharing">INFORME ANALITICO DE OBLIGACIONES DIFERENTES DE FINANCIAMIENTOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1F8EI5wKwa2nDFEjmAkyTdkmBNbrrSJZE/view?usp=sharing">INFORME DE LA DEUDA PUBLICA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1kSW5bemRqxdl5AXH_fEJWenv6Y3JZWMB/view?usp=sharing">INFORME SOBRE PASIVOS CONTIGENTES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/123eb9H-nVQ3JWldSq4uTQKtf1HDYtzyY/view?usp=sharing">INTEGRACION DEL PRESUPUESTO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1T0bMu4Q7pPuwsJrnlLx0px0t1obq2kgT/view?usp=sharing">INTERESES DE LA DEUDA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1aJDfLdclNin9zWGiX239g7qxVktBAaf-/view?usp=sharing">NOTAS DE MEMORIA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1qlfqJD_0cliPrDmMYTRhPowwdTiAEEjF/view?usp=sharing">RELACION DE CUENTAS BANCARIAS PRODUCTIVAS ESPECIFICAS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1oKiZZ3fKVFa1FzEjr_nd_owcCxUN4BDV/view?usp=sharing">RELACION DE ESQUEMAS BURSATILES Y COBERTURA FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
<template>
    <section class="revista mt-3">
        <!-- Apartado revista embebida -->
        <div class="container">
            <div class="d-flex justify-content-between">
                <p class="title3_vino">Revista Institucional 2 Edición</p>
                <div class="p-2 bd-highlight">
                    <router-link class="btn-agua" :to="{name: 'revista'}"><i class="fa fa-arrow-left"></i> Atras</router-link>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <iframe 
                    width="620px" 
                    height="531px" 
                    src="https://www.yumpu.com/es/embed/view/BC2m3t7vIUd8epDP" 
                    frameborder="0" 
                    allowfullscreen="true" 
                    allowtransparency="true">
                </iframe>
            </div>
            <div class="links_revista mb-5">
                <a class="txt-vino txt-mini fw-bold" href=""> <i class="fa-regular fa-folder carpeta_mini me-3 ms-4"></i> Descargar Revista en Pdf (Calidad Alta)</a>
                <a class="txt-vino txt-mini mx-3 fw-bold" href=""> Descargar Revista en Pdf (Calidad Media)</a>
                <a class="txt-vino txt-mini fw-bold" href=""> Descargar Revista en Pdf (Calidad Baja)</a>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>
<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">Cierre Mensual</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1eWIvtnZqG6uysmqzWCZw6WGw9BfECoex/view?usp=sharing">APLICACION Y-DISPONIBILIDAD-DEL-PRESUPUESTO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1KKqa-SxLQvXGzPIQTgM2UMQgLaSug7SK/view?usp=sharing">BALANCE PRESUPUESTARIO</a>
                                <li class="d-none">2022</li> <li></li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1COKfEOFqJ2oXoXy08_opQmLQmVLUvmow/view?usp=sharing">BALANZA COMPROBACION ACUMULADA </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/12msnDOczEWQia_7nQfPcjLvIW_q_dZzR/view?usp=sharing">BALANZA DE COMPROBACION MENSUAL </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1jKPDTJWg3cVEPM5y4pILy44dfuYivLXC/view?usp=sharing">ENDEUDAMIENTO NETO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1p0HMGETj2pNhtriflK4tk4lblZoOqXW5/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1e6oIbx70rmOw8hW69yqTqQnxGHG1FVJx/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DEL EJERCICIO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/100k8wftBMLWHXnewd8KJaOv55K14_Zb8/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fnH3PP0wXDEM8LJnIOOQ99_U4cIii0sk/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1-9ycLLif7Tdz4iIuIjhot7SmqZhJkkGf/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1qKL_f3n37_p3cwWew6sbrI3o1f7eRenj/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1eeozsCzxHAwCIkxTh_PB_iz7xD2DhUhd/view?usp=sharing">ESTADO ANALITICO DE LA DEUDA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1AUxNvECPxXORuhEaMcASyA3K1rEK4NNk/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1_KllVOO-FTxmN6OHLF_CL0iPb0JYdQhR/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vbMygpnUVqbLZnbiOHNXwq7416SJRtlX/view?usp=sharing">ESTADO DE ACTIVIDADES POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11REVYBl72WnNktIdUE_bBnlkpL0lD4di/view?usp=sharing">ESTADO DE ACTIVIDADES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1U0ncLTwgg7m-2Slm8XpwqCOriuBOwOtk/view?usp=sharing">ESTADO DE CAMBIOS EN LA SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Az6XBHWOz6ZaYHNba_8tArGBfX-tr8kq/view?usp=sharing">ESTADO DE FLUJOS DE EFECTIVO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/12pB3Sz09Hz_rgzf9uCJ2D1Y9eamALDdJ/view?usp=sharing">ESTADO DE SITUACION FINANCIERA DETALLADO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1zlERCxIO9EpW-AOlW1buo_LKwmu3ANz8/view?usp=sharing">ESTADO DE SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1CdZ1aSFfTQg-CsSJH7BaGn_GmwcOdbF3/view?usp=sharing">ESTADO DE SITUACION FINANCIERA POR RUBROS </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1NXbpRMRL8oFxwwb4x1PNKNkLlM2xQmvp/view?usp=sharing">ESTADO DE VARIACION EN HACIENDA PUBLICA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1DjU9ULEMzDKEs4V27CelIp_exURDUjaH/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DE AÑOS ANTERIORES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/13yVfJeutz0neQj-GN4eqsloNbIRgIUf_/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10xWV7ZAHoAtOqkcIJpSCPRQFeqnCygaP/view?usp=sharing">INDICADORES DE POSTURA FISCAL</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Sh-LB4WZHdE70q78RGlDLDRIAcYW_GDc/view?usp=sharing">INFORME ANALITICO DE OBLIGACIONES DIFERENTES DE FINANCIAMIENTOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1vG_oX75MGFX6Q0go49HJAX1rVlYHE46Q/view?usp=sharing">INFORME DE LA DEUDA PUBLICA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1X_UQbuqDF51MngIC7Vqb67dn_WaLc_Gy/view?usp=sharing">INFORME SOBRE PASIVOS CONTIGENTES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1IIPW80ZQn1KivB-FfO_M6pScNzzlbl58/view?usp=sharing">INTEGRACION DEL PRESUPUESTO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1H-kenNJ4MdYB-8O1XolGEVqurrZkwksh/view?usp=sharing">INTERESES DE LA DEUDA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/18ryEE27CrB1n79PXRWL3O_zEHZIo6wl7/view?usp=sharing">NOTAS DE MEMORIA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1JpJLwSPiobl2WhUCGfXki4Cme3neOl0I/view?usp=sharing">RELACION DE CUENTAS BANCARIAS PRODUCTIVAS ESPECIFICAS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EK67AmhphxK1ZWOr_Mhzh2AI332uDvUp/view?usp=sharing">RELACION DE ESQUEMAS BURSATILES Y COBERTURA FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
<template>
    <section class="revista mt-3">
        <!-- Apartado revista embebida -->
        <div class="container">
            <div class="d-flex justify-content-between">
                <p class="title3_vinolg">Revista Institucional</p>
                <div class="p-2 bd-highlight">
                    <router-link class="btn-agua" :to="{name: 'home'}"><i class="fa fa-arrow-left"></i> Atras</router-link>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-3">
                <iframe 
                    width="800px" 
                    height="700px" 
                    src="https://www.yumpu.com/es/embed/view/E4ZUdcwyODpg0oQx" 
                    frameborder="0" 
                    allowfullscreen="true"  
                    allowtransparency="true">
                </iframe>
            </div>
            <div class="links_revista">
                <a class="txt-vino txt-mini fw-bold" href=""> <i class="fa-regular fa-folder carpeta_mini me-3 ms-4"></i> Descargar Revista en Pdf (Calidad Alta)</a>
                <a class="txt-vino txt-mini mx-3 fw-bold" href=""> Descargar Revista en Pdf (Calidad Media)</a>
                <a class="txt-vino txt-mini fw-bold" href=""> Descargar Revista en Pdf (Calidad Baja)</a>
            </div>
        </div>
        <!-- Apartados ediciones -->
        <div class="bg-gris container_ediciones">
            <div class="container pt-5">
                <p class="title1_vino">Ediciones posteriores</p>
                <div class="row mt-5 px-5 py-5">
                    <div class="col-md-4">
                        <div class="d-flex align-items-baseline">
                            <div class="container-icono-carpeta">
                                <i class="fa-regular fa-folder carpeta"></i>
                            </div>
                            <router-link class="ruta" :to="{name: 'revista_2edicion'}">
                                <h6 class="txt-vino ms-3 fw-bold text-center">2 EDICIÓN</h6>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex align-items-baseline">
                            <div class="container-icono-carpeta">
                                <i class="fa-regular fa-folder carpeta"></i>
                            </div>
                            <h6 class="txt-vino ms-3 fw-bold text-center">PROXIMAMENTE</h6>
                        </div>
                    </div>
                    <div class="col-md-4">
                       <div class="d-flex align-items-baseline">
                            <div class="container-icono-carpeta">
                                <i class="fa-regular fa-folder carpeta"></i>
                            </div>
                            <h6 class="txt-vino ms-3 fw-bold text-center">PROXIMAMENTE</h6>
                        </div>
                    </div>
                </div>
                <div class="row px-5 py-4">
                    <div class="col-md-4">
                        <div class="d-flex align-items-baseline">
                            <div class="container-icono-carpeta">
                                <i class="fa-regular fa-folder carpeta"></i>
                            </div>
                            <h6 class="txt-vino ms-3 fw-bold text-center">PROXIMAMENTE</h6>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex align-items-baseline">
                            <div class="container-icono-carpeta">
                                <i class="fa-regular fa-folder carpeta"></i>
                            </div>
                            <h6 class="txt-vino ms-3 fw-bold text-center">PROXIMAMENTE</h6>
                        </div>
                    </div>
                    <div class="col-md-4">
                       <div class="d-flex align-items-baseline">
                            <div class="container-icono-carpeta">
                                <i class="fa-regular fa-folder carpeta"></i>
                            </div>
                            <h6 class="txt-vino ms-3 fw-bold text-center">PROXIMAMENTE</h6>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
<script>
export default {
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>
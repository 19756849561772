<template>
    <div class="container mt-3">
        <div class="d-flex justify-content-between">
            <p class="title3_vinolg">COMITE DE ÉTICA</p>
            <div class="p-2 bd-highlight">
                <router-link class="btn-agua" :to="{name: 'home'}"><i class="fa fa-arrow-left"></i> Atras</router-link>
            </div>
        </div>

        <div class="table-container">
                <p class="title-table">
                    Archivos de ejercicios
                </p>
                <p class="formato-table">
                    Formato por Año 2024
                </p>
                    <table class="table table-striped">
            <thead>
                <tr>
                    <td scope="col" class="bg-vino-secondary text-white"><h4 class="mb-0">Nombre de formato</h4></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1gIcEZcTHV2jfTOmQeT7lru6swJtcc0yC/view?usp=sharing" target="_blank">Acta de Integración del Comité</a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1K0TyUqQksi_OKwzlw-yN1EOz2QAVuJ3k/view?usp=sharing" target="_blank">PAT</a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1ueMI6a2jDJazPDQSpBcnUsHqCp63a9X-/view?usp=sharing" target="_blank">INDICADORES Y MECANISMOS </a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1ui6N1o5A4RLSmDj_RiHvwPDrav1UqBna/view?usp=sharing" target="_blank">CÓDIGO DE CONDUCTA</a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1IiqqH5yhC8PVebjMbiseKrtpuroF3ZQo/view?usp=sharing" target="_blank">ACTA DE LA PRIMERA SESIÓN ORDINARIA</a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1eXhkjdnm8hEEvIW_SOgzAHv4ChNGL_Jn/view?usp=sharing" target="_blank">INTEGRANTES DEL COMITE</a></td>
                </tr>
            </tbody>
        </table>
        </div>

        <div class="table-container">
                <p class="title-table">
                    Archivos de ejercicios
                </p>
                <p class="formato-table">
                    Formato por Año 2023
                </p>
                    <table class="table table-striped">
            <thead>
                <tr>
                    <td scope="col" class="bg-vino-secondary text-white"><h4 class="mb-0">Nombre de formato</h4></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1ixeHpArbczH8KNVVtBFuaPuk0uaxprbw/view?usp=sharing" target="_blank">PAT </a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/12_mFn-tqzxjPEcDers__qo2Gsf8raVFQ/view?usp=sharing" target="_blank">DIRECTORIO INTEGRANTES DEL COMITE DE ETICA</a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1V7581Tvvhl7Q2pgwv0fyxM9nhH_M6C-q/view?usp=sharing" target="_blank">INDICADORES Y MECANISMOS</a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/117v2MZqmW4z3jTnoV7oEmOXLg-NM5L7y/view?usp=sharing" target="_blank">Acta de la Cuarta Sesión Ordinaria.13 diciembre 2023</a></td>
                </tr>
                <tr>
                    <td><a href="https://drive.google.com/file/d/1T9xChoUwq-MPq5aZ-GGkfNi2u7KdgeBl/view?usp=sharing" target="_blank">Informe Anual de Actividades Comité de Ética y prevención de Conflictos.INESA 2023</a></td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="table-container">
                
                <p class="formato-table">
                    Formato por Año 2022
                </p>
                    <table class="table table-striped">
            <thead>
                <tr>
                    <td scope="col" class="bg-vino-secondary text-white"><h4 class="mb-0">Nombre de formato</h4></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/ACTA%20DE%20APROBACIÓN%20DE%20PAT%202022%20FIRMADA.pdf'" target="_blank">PAT </a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/ASESORES%20comitre%20de%20etica2022.pdf'" target="_blank">DIRECTORIO DE ASESORES	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/CONSEJEROS%20comite%20de%20etica2022.pdf'" target="_blank">DIRECTORIO DE CONSEJEROS</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022./DIRECTORIO%20comite%20de%20etica2022-.pdf'" target="_blank">DIRECTORIO DE CEPCI	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022./CÓDIGO%20DE%20CONDUCTA%202022%20FIRMADO%20PDF.pdf'" target="_blank">CODIGO DE CONDUCTA</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022./ACTA%20DE%20APROBACIÓN%20DE%20PROTOCOLO%20DE%20ATENCIÓN%20%20A%20DENUNCIAS%20FIRMADO.pdf'" target="_blank">PROTOCOLO DE ATENCION DE DENUNCIAS	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/protocolo_actuacion.pdf'" target="_blank">PROTOCOLO DE ACTUACION	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/Triptico%20Comité%20de%20Ética_2022.pdf'" target="_blank">TRIPTICO</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/PROTOCOLO%20DE%20ATENCIÓN%20A%20QUEJAS%20Y%20DENUNCIAS%20APROBADO%20Y%20FIRMADO.pdf'" target="_blank">ACTA DE PROTOCOLO APROBADO ATENCION Y QUEJAS	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022./INFORME%20ANUAL%20DE%20ACTIVIDADES%20INESA%202022.pdf'" target="_blank">INFORME ANUAL	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/ACTA%20DE%20APROBACIÓN%20DE%20BASES%202022%20FIRMADA.pdf'" target="_blank">ACTA DE APROBACIÓN DE BASES	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/BASES%20DE%20INTEGRACIÓN,%20ORGANIZCIÓN%20Y%20FUNCIONAMIENTO%202022.pdf'" target="_blank">BASES DE INTEGRACIÓN, ORGANIZACIÓN Y FUNCIONAMIENTO	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/ACTA%20DE%20INSTALACIÓN%20COMITÉ%20DE%20ÉTICA%20INESA%202022.pdf'" target="_blank">ACTA DE INSTALACIÓN COMITÉ DE ÉTICA INESA</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/PAT%202022%20FIRMADO%20INESA.pdf'" target="_blank">PAT 2022</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2022/ACTA%20DE%20APROBACIÓN%20DE%20PAT%202022%20FIRMADA.pdf'" target="_blank">ACTA DE APROBACIÓN DE PAT</a></td>
                </tr>
            </tbody>
        </table>
        </div>

        <div class="table-container">
                <p class="formato-table">
                    Formato por Año 2021
                </p>
                    <table class="table table-striped">
            <thead class="">
                <tr>
                    <td scope="col" class="bg-vino-secondary text-white"><h4 class="mb-0">Nombre de formato</h4></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2021/ACTA%20FIRMADA%20PAT%202021.pdf'" target="_blank">PAT</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2021/DIRECTORIO%20DE%20ASESORES2021.pdf'" target="_blank">DIRECTORIO DE ASESORES	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2021/DIRECTORIO%20DE%20CONSEJEROS2021.pdf'" target="_blank">DIRECTORIO DE CONSEJEROS</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/Protocolo.pdf'" target="_blank">PROTOCOLO DE ACTUACIÓN	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2021/Triptico%20Comité%20de%20Ética.pdf'" target="_blank">TRIPTICO</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/2021/PROTOCOLO%20DE%20ATENCIÓN%20INESA%202021.pdf'" target="_blank">ACTA DE PROTOCOLO APROBADO ATENCION Y QUEJAS	</a></td>
                </tr>
                <tr>
                    <td><a href="" target="_blank">INFORME ANUAL</a></td>
                </tr>
            </tbody>
        </table>
        </div>

        <div class="table-container">
                <p class="formato-table">
                    Formato por Año 2020
                </p>
                    <table class="table table-striped">
            <thead class="bg-vino-secondary">
                <tr>
                    <td scope="col" class="text-bold bg-vino-secondary text-white"><h4 class="mb-0">Nombre de formato</h4></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/DIRECTORIO%202020/ACTA%20DE%20APROBACIÓN%20DE%20INDICADORES%20INESA%202020.pdf'" target="_blank">ACTA DE PROTOCOLO APROBADO	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/DIRECTORIO%20DE%20aSESORES.pdf'" target="_blank">DIRECTORIO DE ASESORES</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/DIRECTORIO%20DE%20CONSEJEROS.pdf'" target="_blank">DIRECTORIO DE CONSEJEROS</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/Protocolo.pdf'" target="_blank">PROTOCOLO DE ACTUACIÓN	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/TRIPTICO%20COMITÉ%20DE%20ÉTICA.pdf'" target="_blank">TRIPTICO</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/DIRECTORIO%20comitre%20de%20etica.pdf'" target="_blank">DIRECTORIO COMITE	</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'CODIGO_ETICA/PDF/Informe-anual.pdf'" target="_blank">INFORME ANUAL</a></td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mb-4">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSelfkbvpg2jVHbVuxGFlKmYgcHrCw0-5zRejVPALk_Nwhh4KQ/viewform?pli=1" target="_blank" class="btn text-white me-3 p-2 bg-vino">Buzón de quejas</a>
            <a href="http://iea.transparencia.chiapas.gob.mx/" target="_blank" class="btn text-white p-2 bg-vino">Transparencia</a>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>
<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">Cierre Mensual</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1-hd4K5ECHbNrDPLBFblqUi_gHp6NTgEW/view?usp=sharing">Aplicacion-disponibilidad-del-presupuesto</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1uus_uz06qqkHc9d3EQPH7rilqWkEEoZJ/view?usp=sharing">Balanza-Comprobacion-Acumulada</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1QfuHTaztWf7wseTy7gaPkT_mD5yGjKTr/view?usp=sharing">Balanza-Comprobacion-Mensual</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1t9EpKo_4Xr49rAiOwBXEHfCn2mz6ypmr/view?usp=sharing" class="link">Edo-Situacion-Financiera-por-cuenta</a></li>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1frAb3W7y51xKMu-5o8GttVMm_JBtVEZt/view?usp=sharing">Edo-Situacion-Financiera-por-rubro</a>
                                <li class="d-none">2023</li>

                            </ul>
                        </div>
                    </div>
                </div>
               
     
            </div>
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
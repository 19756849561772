<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: '/Ley-de-disiplina1'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">TERCER TRIMESTRE</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1mF7XiguNSvL90MV1c1hRFtbqsP6F0vb3/view?usp=sharing">LDF-6a Clasificación por Objeto del Gasto (Capitulo y Concepto)</a>
                                <li class="d-none">2023</li>
                                
                   
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1mF7XiguNSvL90MV1c1hRFtbqsP6F0vb3/view?usp=sharing">LDF-6b Clasificacion Administrativa</a>
                                <li class="d-none">2023</li> <li></li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rxyPw5xZLy1iHlW_sWD4LG2wYHIMnvrb/view?usp=sharing">LDF-6c Clasificacion Funcional (Finalidad y Función)</a>
                                <li class="d-none">2023</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1i1KiPet-zUEK6NL26ZDMUuP3XRYd0Yzg/view?usp=sharing">LDF-6d Clasificacion de Servicios Personales por Categoria</a>
                                <li class="d-none">2023</li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            
          
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
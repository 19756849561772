<template>
  <div class="container mt-3">
    <div class="col-md-12 mt-3 mb-3 pe-5" style="text-align: right">
      <a href="/armonizacionContable" class="btn-agua"
        ><i class="fas fa-arrow-left"></i> Atras</a
      >
      <a href="/" class="ms-2 btn-agua"><i class="fas fa-house"></i> Inicio</a>
    </div>
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <p class="title1_vino">
          Inventarios
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2023</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1MjQThx5BujQ4saDlqFRR0ejaY9mmC4A9/view?usp=sharing"
                  target="_blank"
                  >PRIMER TRIMESTRE</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1zbeQ9ETsL0Od2fhgatE8FVSHgFjG1bQ5/view?usp=sharing"
                  target="_blank"
                  >SEGUNDO TRIMESTRE</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2022</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1M_rpgsr94c5-uoK-yTOmHB2uHNN1ss-X/view?usp=sharing"
                  target="_blank"
                  >PRIMER TRIMESTRE</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1-g9wUtBZ_U6_cB8csdBZJCRIB8YbMaeN/view?usp=sharing"
                  target="_blank"
                  >SEGUNDO TRIMESTRE</a
                >
              </td>
            </tr>
            <tr>
              <td>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2021</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/12mTWTpOpghCWZ7B-G1w2ZRHwtYI9Qr_G/view?usp=sharing"
                  target="_blank"
                  >PRIMER TRIMESTRE</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1Kaqir9vfGAovcdCph1iELgB7Q3pnVKX9/view?usp=sharing"
                  target="_blank"
                  >SEGUNDO TRIMESTRE</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2020</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >PRIMER TRIMESTRE	</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >SEGUNDO TRIMESTRE</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2019</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >PRIMER TRIMESTRE	</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >SEGUNDO TRIMESTRE</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >TERCER TRIMESTRE</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >CUARTO TRIMESTRE</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<template>
    <div class="container mt-3">
        <div class="d-flex bd-highlight">
            <div class="p-2 flex-grow-1 bd-highlight">
                 <p class="title3_vino">ARCHIVÍSTICA</p>
            </div>
            <div class="p-2 bd-highlight">
                <router-link class="btn-agua" to="/"><i class="fa fa-arrow-left"></i> Atras</router-link>
            </div>
        </div>
        <div class="table-container">
                <p class="title-table">
                    Archivos de ejercicios
                </p>
                <p class="formato-table">
                    Formato por Año 2023
                </p>
                    <table class="table table-striped">
            <thead>
                <tr>
                    <td scope="col" class="bg-vino-secondary text-white"><h4 class="mb-0">Nombre de formato</h4></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'" target="_blank">ACUERDO GENERAL</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'" target="_blank">SISTEMA INSTITUCIONAL DE ARCHIVOS</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'" target="_blank">NOMBRAMIENTO DE LA COORDINADORA DE ARCHIVOS</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'" target="_blank">NOMBRAMIENTO DE LA RESPONSABLE DEL ÁREA DE CORRESPONDENCIA</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'" target="_blank">NOMBRAMIENTOS DE LOS RESPONSABLES DE ARCHIVOS DE TRÁMITE</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/2.4%20.-%20Nombramiento_Responsable_Arch_de_Concentración_2023.pdf'" target="_blank">NOMBRAMIENTO DEL RESPONSABLE DE ARCHIVO DE CONCENTRACIÓN</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'" target="_blank">GRUPO INTERDISCIPLINARIO (ACTA DE INSTALACIÓN)</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'" target="_blank">GRUPO INTERDISCIPLINARIO (REGLAS DE OPERACIÓN)</a></td>
                </tr>
                <tr>
                    <td><a :href="urlServe+'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'" target="_blank">P.A.D.A  - PROGRAMA ANUAL DE DESARROLLO ARCHIVÍSTICO (PADA) 2023</a></td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mb-4">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSelfkbvpg2jVHbVuxGFlKmYgcHrCw0-5zRejVPALk_Nwhh4KQ/viewform" target="_blank" class="btn text-white me-3 p-2 bg-vino">Buzón de quejas</a>
            <a href="http://iea.transparencia.chiapas.gob.mx/" target="_blank" class="btn text-white p-2 bg-vino">Transparencia</a>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>
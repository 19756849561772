<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PRESUPUESTARIOS</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1xxsJ4GAlGS-KPYhna2-plEic5J9IbKI1/view?usp=sharing">ESTADO-ANALITICO-DE-INGRESOS</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fWAfkLIRXYkUAhZF_2tzE8hdSmmqxtyw/view?usp=sharing">ENDEUDAMIENTO-NETO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>

                                <li><a href="https://drive.google.com/file/d/1-dwqF3XaG3YGr2a6H3N2m8zyBpvGXvHS/view?usp=sharing" class="link">INTERESES-DE-LA-DEUDA</a></li>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1K6w0lRyzFdLGQTuKeIfEV2mi690xPoZt/view?usp=sharing">RPA-1-Edo-Analit-Clasif-Objt-Gto</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1WEoeuzdxxtzvUiEMvxcxtlo4OWY_ieCX/view?usp=sharing">RPA-2-Edo-Analit-Clasif-Economica</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        
                         <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/15AT_VrrzpUELGzmj1GLVorFKhvdoYXu1/view?usp=sharing">RPA-3-Edo-Analit-Clasif-Admitiva-Org</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1FIzphAEsnrmb75IMiLZl_kM5XirTSynM/view?usp=sharing">RPA-4-Edo-Analit-Clasif-Admitiva-pod</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1aX2sssgCSmh4TnB9Z73Gv2ciGPCjp-be/view?usp=sharing">RPA-5-Edo-Analit-Clasif-Admitiva-ent</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1SuTB6iJuI1kbmhMbEi-hLhR4nUMI0Tn2/view?usp=sharing">RPA-6-Edo-Analit-Clasif-funcional</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">ESTADOS E INFORMES PROGRAMATICOS</p>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1qEawQNal93ul3HwQX95053Ks1YP1Iw49/view?usp=sharing">PROGRAMAS PROYECTOS INVERSION</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1YHRz1L2v9vir6XOJ1jUY0IhFQwu7EXUt/view?usp=sharing">CATEGORIA PROGRAMATICA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">REINTEGROS FEDERALIZADOS</p>
                    </div>
                </div>
                <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1oJ5S0iCvBfNxhy0IagDjvh62jB4Ul23M/view?usp=sharing">FORMATO FEDERALIZADO REINTEGROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION LEY DISCIPLINA FINANCIERA</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/12ekLRtlqo1uKT21Fn-XjVdv4JqJIUhxo/view?usp=sharing">LDF-6a-Edo-Analit-Detallado-Clasif-Obj-Gto</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1G0HoeO9P3v-tFrzzNfvWSsOlIxpEuy1N/view?usp=sharing">LDF-6b-Edo-Analit-Detallado-Clasif-Admitiva</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10z6w6jVtmK8M88F9YMfniCLAOuhS5Plg/view?usp=sharing">LDF-6c-Edo-Analit-Detallado-Clasif-funcional</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Afx4cA-N7S3LhqISWqZJIe99hiPOjN9i/view?usp=sharing">LDF-6d-Edo-Analit-Detallado-Clasif-Serv-pers</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href=""></a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                <div class="title-contend">
                        <p class="title1_vino">INFORMACION DE INTEGRACION Y ANALISIS</p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1oUcjnCARWwSRBo2gi-w3-cDAwjM2Mq3D/view?usp=sharing">Reportes Presupuesto (EP-10)</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1RmiGaXOzfmNNVB9BXCbcqVhmvrILQHMW/view?usp=sharing">Analisis-Recursos-Federales-Etiquetados</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/17usUEEmf-ZBmC1pqiq13hp4NcA1jhkI-/view?usp=sharing">PRINCIPALES ADECUACIONES AL PRESUPUESTO DE EGRESOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1EY_IsHunpM5WAPmRbpIidzRoFFX2L6UI/view?usp=sharing">PRESUPUESTO DE EGRESOS POR TIPO DE RECURSO FUENTE DE FINANCIAMIENTO Y RAMO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1DsHLO2U4Sd6swuuRqAF9VyuZuVDZgxH2/view?usp=sharing">Aplicacion-Presupuesto-por-rubros</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1V7o47XePBiE26Em6L4WjkmHJMw6X96wt/view?usp=sharing">NO ETIQUETADO 1 RECURSOS FEDERALES CLASIFICACION ADMINISTRATIVA EP-5</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rT2OsGIrYBRzwJ01MKfAOZenN-7TPacD/view?usp=sharing">NO ETIQUEDADO 1 RECURSOS FISCALES (CLASIFICACION-ADMINISTRATIVA) EP-1</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1XR6BpROP5d35h_MEt09AdKJ8QzNHaCaA/view?usp=sharing">JUSTIFICACION A LA APLICACION DEL PRESUPUESTO POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                        
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1HQ6CzpJdlvnDD7X5NEqRijTCJpEMJTOI/view?usp=sharing">Cierre 2022</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10PaFZpZWqxN-5dAgCVU9bpXPPvR_VWOt/view?usp=sharing">COMPARATIVO Y ANÁLISIS PRESUP DEVENGADO POR OBJETO DEL GASTO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1fiUGQ77Djycyv_ucbykn0tAIZ_rtUVur/view?usp=sharing">ANÁLISIS DE PARTIDAS POR CLASIFICACIÓN ADMITIVA EP-01</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1_GRf4myaOLkrbZlJVjuws3djPMJyYAnT/view?usp=sharing">CAPÍTULOS, CONCEPTOS, PARTIDA GENÉRICA ESPECÍFICA EP-03</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11C6I_SDifvZq4vaRYpGdYtO-n4s3WJpp/view?usp=sharing">ETIQUETADO 2 RECURSOS FEDERALES CLASIFICACIÓN ADMINISTRATIVA EP-5</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1hOV-9vzRIL6WMbBGY1RX4UxyNf0WThpf/view?usp=sharing">APLICACION DEL PRESUPUESTO DEVENGADO POR TIPO DE RECURSO POR FONDO Y CAPITULO DE GASTO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1_jpqBjhcVNi7W59ZwrvZtTTRL7x7B2eQ/view?usp=sharing">RESUMEN TIPO DE GASTO Y PARTIDA EP-08</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Am6A9niDbggsKiATJ_hyZ7c1Wqp4zZxM/view?usp=sharing">APLICACION DEL PRESUPUESTO DEVENGADO POR FONDO Y SUBFUENTE DE FINANCIAMIENTO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1_JGIrjaRs9lRDry6byCrEaMAOZ28HIMX/view?usp=sharing">ANALISIS DEL CAPITULO 5000 BIENES MUEBLES INMUEBLES E INTANGIBLES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11I4GuOmyqF1tTdQo3QhLKGr2zaiivShz/view?usp=sharing">ANALISIS DEL CAPITULO 4000 TRANSFERENCIAS ASIGNACIONES SUBSIDIOS Y OTRAS AYUDAS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            
            
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
<template>
  <div class="container mt-3">
    <div class="col-md-12 mt-3 mb-3 pe-5" style="text-align: right">
      <a href="/armonizacionContable" class="btn-agua"
        ><i class="fas fa-arrow-left"></i> Atras</a
      >
      <a href="/" class="ms-2 btn-agua"><i class="fas fa-house"></i> Inicio</a>
    </div>
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <p class="title1_vino">
          Cuenta Pública
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2024</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal1T24'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
            
            
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera1T24'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestalT'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinancieraT'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Ata%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinancieraT'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestalT'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivisica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera23T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal24T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2023</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal2T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
            
            
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal2T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal2T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera2T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera3T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal3T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera234T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal234T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2022</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal2T'}"
                  >Estados Financieros Enero</router-link
                >
              </td>
            </tr>
            
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.4%20.-%20Nombramiento_Responsable_Arch_de_Concentración_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera224T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal224T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2021</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 1er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.4%20.-%20Nombramiento_Responsable_Arch_de_Concentración_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera214T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal214T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2020</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 1er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.4%20.-%20Nombramiento_Responsable_Arch_de_Concentración_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicafinanciera4T'}"
                  >Financiera</router-link
                >
              </td>
            </tr>
            <tr>
              <td>
                <router-link
                  :to="{name: 'cuentapublicapresupuestal4T'}"
                  >Presupuestal</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2019</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    ''
                  "
                  target="_blank"
                  >Cuenta Pública 1er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1069nN7IW0st3e3aD34K4DqKYDdNZYoKA/view">Financiera</a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1_mOvYx--MMrpUQeFBPdFF0I6g5ab0y3r/view">Presupuestal </a>
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    ''
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1bP3DS7_C9jKScSL2YP4lIii3rVQiHdfT/view">Financiera</a>

              </td>
            </tr>
            <tr>
              <td >
                <a
                href="https://drive.google.com/file/d/1kPXPHa1sVYzhbZAv6BeDJWiUNwsVs63Y/view">Presupuestal </a>
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    ''
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1w0VEmgB29RxuQeMGWpex_iZUUdHFgurL/view">Financiera</a>

              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1zJY71c-j7tNdKBXO8KT5xvL5BJebdji3/view">Presupuestal </a>
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/15qT_0jugIWWfdmFp9I-c4tM1N5qeg8af/view">Financiera</a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1eLJyAHtuzBoZBM321zlkWE2OALpjzjmV/view">Presupuestal </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2018</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    ''
                  "
                  target="_blank"
                  >Cuenta Pública 1er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1le8a_FWeiiFXHjA-bcBsMUeYcV1wN7eE/view">Financiera </a>

              </td>
            </tr>
            <tr>
              <td>
                <a
                 href="https://drive.google.com/file/d/122-3m2HMVsoijBdbmne5RjEkMn874ugi/view">Presupuestal </a>

              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    ''
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1mVWOeBQBKfa5KXlO1IZ0yRbn7Ck-It-V/view">Presupuestal y Financiera </a>

              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    ''
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1aT70eEROplzzP8QgfKwhwZR9UW27b4oQ/view">Financiera</a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1G0b-KYproTVbGd45vesa32Lquj_eFBjf/view">Presupuestal</a>
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    ''
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1HeQfx1XMVcfyOugvQIxXc0mTzWIcuY8S/view">Financiera</a>
                
              </td>
            </tr>
            <tr>
              <td>
                <a
                href="https://drive.google.com/file/d/1qBTLbhSKGrq7NLznM17VE__WM9T3eahE/view">Presupuestal</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2017</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 1er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública Financiera y Presupuestal </a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2016</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 1er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Presupuestal </a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2015</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Nombre de formato</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 1er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública Presupuestal 1er. Trimestre 2015	</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública Financiera 1er. Trimestre 2015	 </a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Información Contable 2do. Trimestre 2015</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 2do. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >EP-01 Analítico de Partidas por Clasificación Administrativa	</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >EP-03 Por Capítulos, Concepto, Partida Genérica y Partida Específica por Clasificación Administrativa	</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >EP-08 Tipo de Gasto y Partida</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.3.1-%20Nombramiento_Resposable_Arch_en_Trámite_DG.pdf'
                  "
                  target="_blank"
                  >Información De Integración	</a
                >
            </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important;"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/3.-%20Acta%20de%20Instalación%20del%20Grupo_Interdisciplinario_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 3er. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/4.-%20Reglas%20de%20Operación%20del%20Grupo%20Interdisciplinario_INESA_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
            <tr>
              <td class="td-title">
                <a style="color:#8B2851!important"
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Cuenta Pública 4to. Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Financiera</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/PADA_Archivistica_2023.pdf'
                  "
                  target="_blank"
                  >Presupuestal</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<template>
  <div class="container mt-3">
    <div class="col-md-12 mt-3 mb-3 pe-5" style="text-align: right">
      <a href="/armonizacionContable" class="btn-agua"
        ><i class="fas fa-arrow-left"></i> Atras</a
      >
      <a href="/" class="ms-2 btn-agua"><i class="fas fa-house"></i> Inicio</a>
    </div>
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <p class="title1_vino">
          Indicadores de Resultado
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2024</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1t7qZKKc-iUT13xh-AwPRYK69mT2kb1a1/view?usp=sharing"
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1dG1pkK21cskqWDo8-CyOm_7HlZzfGoiQ/view?usp=sharing"
                  target="_blank"
                  ></a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1E0V0zkzW7NlgF2txuzvN23B0nTQj4qHn/view?usp=sharing"
                  target="_blank"
                  ></a
                >
              </td>
            </tr>

            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1ke7e78JItW4P6kK5RE8yxdpmPoAF0Qcm/view?usp=sharing"
                  target="_blank"
                  ></a
                >
              </td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2023</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1Ur6xWCaALQVfRrgbXGQEWezu1DRbTr-e/view?usp=sharing"
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1dG1pkK21cskqWDo8-CyOm_7HlZzfGoiQ/view?usp=sharing"
                  target="_blank"
                  >Segundo Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1E0V0zkzW7NlgF2txuzvN23B0nTQj4qHn/view?usp=sharing"
                  target="_blank"
                  >Tercer Trimestre</a
                >
              </td>
            </tr>

            <tr>
              <td>
                <a
                  href="https://drive.google.com/file/d/1ke7e78JItW4P6kK5RE8yxdpmPoAF0Qcm/view?usp=sharing"
                  target="_blank"
                  >Cuarto Trimestre</a
                >
              </td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2022</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2021</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2020</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2019</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2018</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2017</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2016</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Primer Trimestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2015</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Segundo Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Tercer Trismestre</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
        <div class="col-md-12">
      <div class="table-container">
        <p class="formato-table">Formato por Año 2014</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Indicadores</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Cuarto Trismestre</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
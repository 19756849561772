<template>
  <div>
    <!-- BANNER -->
    <div class="container-subslide">
      <div class="row">
        <div class="col-md-12">
          <div class="text-slide">
            <h1 class="title">Armonización Contable</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENIDO -->
    <div class="container mt-4">
      <div class="row">
        <div class="col-md-12 mb-3 pe-5" style="text-align:right">
          <a href="/" class="btn-agua"><i class="fas fa-home"></i> Inicio</a>
        </div>
        <div class="col-md-12">
          <div class="title-contend">
            <p class="title1_vino">Transparencia</p>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <a href="http://fpchiapas.gob.mx/transparencia/combos/CompetenciaExterno.php?trim=1&anio=2017&idorg=7015" class="card-apart tab-hv card card-header bg-white mb-2" style="text-decoration:none;">
            <!--card solita----->
            <button class="btn btn-link " type="button">
              <span class="text-bold"
                >Diagnostico de competencia
                <span class="subtext"
                  >(Ir a los archivos de competencia)</span
                ></span
              >
              <span class="descargar"
                ><i class="fa-solid fa-arrow-right"></i
              ></span>
            </button>
          </a>
        </div>
        <div class="col-md-12 mt-4">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button tab-hv text-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  FIES
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                    <ul class="links-redirect">
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1HFEiknL48YEkGjoxKfMCdmry4-KOMPsk/view">Contrato Inifech  <i class="fa-solid fa-arrow-down"></i> </a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1WGjB1cpOtG9lVQ7HBcsz0adIoWZY4x1T/view">Convenio Inifech <i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/1s_SqRStWSwXUWvHb28sMGlAHZx8sSlXT/view">Avances 1er Trimestre 2023 <i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/17_IvRl-U5hBNuzhP9Jnk_5jhXQiVYpcq/view">Avances 2do Trimestre 2023 <i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                        <li class="link-tab">
                            <a href="https://drive.google.com/file/d/17r_2apE5P4Xa6U6ExE-8B4JzB-qgYhea/view?usp=sharing">Avances 3 Trimestre 2023 <i class="fa-solid fa-arrow-down"></i></a>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="container mt-4 mb-5">
      <div class="row">
        <div class="col-md-12">
          <div class="title-contend">
            <p class="title1_vino">Armonización contable</p>
          </div>
          <div class="col-md-12 mt-4">
            <a href="/formatos-conac" style="text-decoration:none;" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Formatos CONAC
                  <span class="subtext"
                    >(Ir a los archivos de formatos CONAC)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a href="/cuenta-publica" style="text-decoration:none;" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Cuenta Pública
                  <span class="subtext"
                    >(Ir a cuenta publica)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a href="/indicadores-resultado" style="text-decoration:none" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Indicadores de Resultado
                  <span class="subtext"
                    >(Ir a indicadores de resultado)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a href="/programa-anual-de-adquisiciones" style="text-decoration:none" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Programa Anual de Adquisiciones
                  <span class="subtext"
                    >(Ir a programa anual de adquisiciones)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a style="text-decoration:none" href="/licitacion-2017" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Licitación 2017
                  <span class="subtext"
                    >(Ir a licitaciones 2017)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a style="text-decoration:none" href="/licitacion-2018" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Licitación 2018
                  <span class="subtext"
                    >(Ir a licitaciones 2018)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a style="text-decoration:none" href="/licitacion-2019" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Licitación 2019
                  <span class="subtext"
                    >(Ir a licitaciones 2019)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a style="text-decoration:none" href="inventario-de-bienes" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >Inventario de Bienes
                  <span class="subtext"
                    >(Ir a inventario de Bienes)</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          <a style="text-decoration:none" href="ley-de-disciplina" class="card-apart tab-hv card card-header bg-white mb-2">
              <!--card solita----->
              <button class="btn btn-link" type="button">
                <span class="text-bold"
                  >LEY DE DISCIPLINA
                  <span class="subtext"
                    >(Ir a LEY DE DISCIPLINA )</span
                  ></span
                >
                <span class="descargar">
                  <i class="fa-solid fa-arrow-right"></i></span>
            </button>
          </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <!-- <div class="loader-wrapper">
      <Loader v-if="isLoading"/>
    </div> -->
  </div>
</template>
<script lang="ts">
import Loader from '../Loader.vue';
export default{
  components:{
    Loader,
  },
  data(){
    return{
      isLoading: true,
    };
  },
  mounted()
  {
    setTimeout(() => {
      this.isLoading = false;
    },1000);
  }
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-3"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_vm._m(5),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("PRIMER TRIMESTRE ")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("SEGUNDO TRIMESTRE")])])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_vm._m(6),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf',"target":"_blank"}},[_vm._v("PRIMER TRIMESTRE ")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("SEGUNDO TRIMESTRE")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("TERCER TRIMESTRE")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.urlServe +
                  'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf',"target":"_blank"}},[_vm._v("CUARTO TRIMESTRE")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3 mb-3 pe-5",staticStyle:{"text-align":"right"}},[_c('a',{staticClass:"btn-agua",attrs:{"href":"/armonizacionContable"}},[_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" Atras")]),_c('a',{staticClass:"ms-2 btn-agua",attrs:{"href":"/"}},[_c('i',{staticClass:"fas fa-house"}),_vm._v(" Inicio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex bd-highlight"},[_c('div',{staticClass:"p-2 flex-grow-1 bd-highlight"},[_c('p',{staticClass:"title1_vino"},[_vm._v(" Inventarios ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2023")])])])]),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1MjQThx5BujQ4saDlqFRR0ejaY9mmC4A9/view?usp=sharing","target":"_blank"}},[_vm._v("PRIMER TRIMESTRE")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1zbeQ9ETsL0Od2fhgatE8FVSHgFjG1bQ5/view?usp=sharing","target":"_blank"}},[_vm._v("SEGUNDO TRIMESTRE")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2022")])])])]),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1M_rpgsr94c5-uoK-yTOmHB2uHNN1ss-X/view?usp=sharing","target":"_blank"}},[_vm._v("PRIMER TRIMESTRE")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1-g9wUtBZ_U6_cB8csdBZJCRIB8YbMaeN/view?usp=sharing","target":"_blank"}},[_vm._v("SEGUNDO TRIMESTRE")])])]),_c('tr',[_c('td')])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2021")])])])]),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/12mTWTpOpghCWZ7B-G1w2ZRHwtYI9Qr_G/view?usp=sharing","target":"_blank"}},[_vm._v("PRIMER TRIMESTRE")])])]),_c('tr',[_c('td',[_c('a',{attrs:{"href":"https://drive.google.com/file/d/1Kaqir9vfGAovcdCph1iELgB7Q3pnVKX9/view?usp=sharing","target":"_blank"}},[_vm._v("SEGUNDO TRIMESTRE")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2020")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"bg-vino-secondary text-white",attrs:{"scope":"col"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("LIBRO DE INVENTARIOS DE BIENES, MUEBLES E INMUEBLES 2019")])])])])
}]

export { render, staticRenderFns }
<template>
    <div class="mb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-12 mt-3 pe-5" style="text-align:right">
                        <router-link :to="{name: 'formatosCONAC'}" class="btn-agua"><i class="fas fa-arrow-left"></i> Atras</router-link>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="title1_vino">Cierre Mensual</p>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/16bu7P6-fZJOSnqgPGIbndhfqP0zetuUh/view?usp=sharing">APLICACION Y-DISPONIBILIDAD-DEL-PRESUPUESTO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1DWUEam1v6MH1jvGDLTIl_c_z58yPlRZt/view?usp=sharing">BALANCE PRESUPUESTARIO</a>
                                <li class="d-none">2022</li> <li></li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1V3rwlosYlAhNDOeKrmA71vEeTXbiqJcc/view?usp=sharing">BALANZA COMPROBACION ACUMULADA </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/14w8TwzvuKFhSUk5GSPAIK-5sJOLnREVr/view?usp=sharing">BALANZA DE COMPROBACION MENSUAL </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1rkpdzqra6JjK6bYq0ATymLOXnSI0QxJb/view?usp=sharing">ENDEUDAMIENTO NETO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1FAsvNlh00kRKQpYH2l4vb_NADFOQ41XQ/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/10C3TV1NB-CL8xQTYmQwjwbZMz2GlUqC7/view?usp=sharing">ESTADO ANALITICO DE INGRESOS CON RECURSOS DEL EJERCICIO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1SXNQj2V0nAkuVmhj-rr2S6rD0-ZjPVxx/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DE AÑOS ANTERIORES </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ry_q8DTGSvYdH-1E7ihdkWC9PZjXkEBD/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1pZDzQLtWZy3-NOPOzEOzH4XC_3ehZgLV/view?usp=sharing">ESTADO ANALITICO DE INGRESOS DETALLADO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/11jk4tETkKr6vizcw0jpxNLMVhvzrkBc2/view?usp=sharing">ESTADO ANALITICO DE INGRESOS</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1f-JS05W3jFNCEzu71o1XV7XDRDlwYBHr/view?usp=sharing">ESTADO ANALITICO DE LA DEUDA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1z2GCD-HAOgNkr01dttSDsM7yD3DWUbC7/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1n6WhTMUSi9aeDS9TNTAdnl_2XRBOBP3v/view?usp=sharing">ESTADO ANALITICO DEL ACTIVO</a>
                                <li class="d-none">2022</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1NwSsCwifN-nma4iSWOKPC4YgKD9b76S3/view?usp=sharing">ESTADO DE ACTIVIDADES POR RUBROS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1NB2MK9MEMa5GkHr1mifuucW3x05BkL6i/view?usp=sharing">ESTADO DE ACTIVIDADES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1ISg63SMJdtIGun_u4ZrcHnWiXY1BtMJp/view?usp=sharing">ESTADO DE CAMBIOS EN LA SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1l2dTN47uo9U519JR-g5FwvUOKWjkZqNn/view?usp=sharing">ESTADO DE FLUJOS DE EFECTIVO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1cagsy_qpnpmYk_gbttbmw2DGX7IYkots/view?usp=sharing">ESTADO DE SITUACION FINANCIERA DETALLADO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1x0gD2MYSRBZKq1ZqlkJtCq3C9egpDzBr/view?usp=sharing">ESTADO DE SITUACION FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1uoHo0o3twA-q0A8-ImTuitP91KIaMHIA/view?usp=sharing">ESTADO DE SITUACION FINANCIERA POR RUBROS </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1hZ1QERESN4VSZZkizCEdh5bqafQ6BM3f/view?usp=sharing">ESTADO DE VARIACION EN HACIENDA PUBLICA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/12i1ld5RfQWknj2hK-2KZiD8f8A2FqAwj/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DE AÑOS ANTERIORES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/15qcna_iS1yYC-8lJ8du9xkA5R-h-9YDN/view?usp=sharing">INDICADORES DE POSTURA FISCAL CON RECURSOS DEL EJERCICIO </a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1O9oSziBj-pXoqZJZbvFNMPwyHmw0veX6/view?usp=sharing">INDICADORES DE POSTURA FISCAL</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1Cd8aP9w1FbMrSobjMy1-kui-veDFBtWl/view?usp=sharing">INFORME ANALITICO DE OBLIGACIONES DIFERENTES DE FINANCIAMIENTOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1YmZbSsUStYQxbBcklYPW5-9c81QNFKRn/view?usp=sharing">INFORME DE LA DEUDA PUBLICA Y OTROS PASIVOS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/17EhiZDAkTz0_yB70PuIa3mmpoZFYj6fU/view?usp=sharing">INFORME SOBRE PASIVOS CONTIGENTES</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1UK27-_uPpx1BwfBO8OZgXWSEagSJg2Y6/view?usp=sharing">INTEGRACION DEL PRESUPUESTO</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1m-9opGeUn7qMhJm87PaLuwBb7HIYcC1V/view?usp=sharing">INTERESES DE LA DEUDA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1KIycKBs088_2wdMqgHvThO2slcwVbj1O/view?usp=sharing">NOTAS DE MEMORIA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1X3NrPOCKUlwfGSzz7O57E_iOJiS_W71S/view?usp=sharing">RELACION DE CUENTAS BANCARIAS PRODUCTIVAS ESPECIFICAS</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-biblioteca mt-3">
                <div class="col-md-3">
                    <div class="contenido-formato">
                        <div class="img">
                            <i class="fa-regular fa-file"></i>
                        </div>
                        <div class="text">
                            <h3 class="title"></h3>
                            <ul>
                                <a
                                href="https://drive.google.com/file/d/1s2cDoFXlFfB0DcmmPAFbH__2I8wyST8q/view?usp=sharing">RELACION DE ESQUEMAS BURSATILES Y COBERTURA FINANCIERA</a>
                                <li class="d-none">2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    export default {
      mounted() {
        window.scrollTo(0, 0);
      },
    };
    </script>
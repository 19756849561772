import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ComiteEtica from '../views/Programas/comiteEtica.vue'
import Revista from '../views/Programas/revista.vue'
import Revista2daEdicion from '../views/Programas/revista2daEdicion.vue'
import encuestas from '../views/Programas/encuestas.vue'
import Convocatoriasdibu2024 from '../views/ProgramaCuencas/Convocatoriasdibu2024.vue'
import Convocatoriasfoto2024 from '../views/ProgramaCuencas/Convocatoriasfoto2024.vue'
import FormularioInscripcion from '../views/ProgramaCuencas/FormularioInscripcion.vue'
import archivistica from '../views/Programas/archivistica.vue'
import conocenos from '../views/Conocenos/conocenosInicio.vue'
import armonizacionContable from '../views/ArmonizacionContable/armonizacionContable.vue'
import bibliotecaVirtual from '../views/Biblioteca/bibliotecaVirtual.vue'
import avisoPrivacidad from '../views/AvisodePrivacidad/avisoPrivacidad.vue'
import programas from '../views/Programas/programasInicio.vue'
import programasDirector from '../views/Conocenos/palabrasDirector.vue'
import organigrama from '../views/Conocenos/organigrama.vue'
import bienvenida from '../views/Conocenos/bienvenida.vue'
import directorioFuncionarios from '../views/Conocenos/directorioFuncionarios.vue'
import formatosCONAC from '../views/ArmonizacionContable/formatosCONAC.vue'
import leydedisciplina from '../views/ArmonizacionContable/leydedisciplina.vue'
import normasemitidasporelconac from '../views/ArmonizacionContable/normasemitidasporelconac.vue'
import cuentaPublica from '../views/ArmonizacionContable/cuentaPublica.vue'
import indicadoresResultados from '../views/ArmonizacionContable/indicadoresResultado.vue'
import programaAnualAdquisiciones from '../views/ArmonizacionContable/programaAnualAdquisiciones.vue'
import licitacion2017 from '../views/ArmonizacionContable/licitacion2017.vue'
import licitacion2018 from '../views/ArmonizacionContable/licitaciones2018.vue'
import licitacion2019 from '../views/ArmonizacionContable/licitaciones2019.vue'
import inventarioDeBienes from '../views/ArmonizacionContable/inventarioBienes.vue'
import cuencas from '../views/Programas/cuencas.vue'
import saneamiento from '../views/Programas/saneamiento.vue'
import estadosFinancieros from '../views/ArmonizacionContable/estadoFinancieroxAnio.vue'
import estadosFinancieros2023 from '../views/ArmonizacionContable/estadoFinancierox2023.vue'
import estadosFinancieros2024ene from '../views/ArmonizacionContable/estadosFinancieros/2024/estadoFinancierox2024ene.vue'
import estadosFinancieros2024feb from '../views/ArmonizacionContable/estadosFinancieros/2024/estadoFinancierox2024feb.vue'
import estadosFinancieros2023ene from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023ene.vue'
import estadosFinancieros2023feb from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023feb.vue'
import estadosFinancieros2023mar from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023mar.vue'
import estadosFinancieros2023abr from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023abr.vue'
import estadosFinancieros2023may from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023may.vue'
import estadosFinancieros2023jun from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023jun.vue'
import estadosFinancieros2023jul from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023jul.vue'
import estadosFinancieros2023sep from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023sep.vue'
import estadosFinancieros2023ago from '../views/ArmonizacionContable/estadosFinancieros/2023/estadoFinancierox2023ago.vue'
import estadosFinancieros2022ene from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022ene.vue'
import estadosFinancieros2022feb from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022feb.vue'
import estadosFinancieros2022mar from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022mar.vue'
import estadosFinancieros2022abr from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022abr.vue'
import estadosFinancieros2022may from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022may.vue'
import estadosFinancieros2022jun from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022jun.vue'
import estadosFinancieros2022jul from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022jul.vue'
import estadosFinancieros2022ago from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022ago.vue'
import estadosFinancieros2022sep from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022sep.vue'
import estadosFinancieros2022oct from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022oct.vue'
import estadosFinancieros2022nov from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022nov.vue'
import estadosFinancieros2022dic from '../views/ArmonizacionContable/estadosFinancieros/2022/estadoFinancierox2022dic.vue'
import estadosFinancieros2021ene from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021ene.vue'
import estadosFinancieros2021feb from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021feb.vue'
import estadosFinancieros2021mar from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021mar.vue'
import estadosFinancieros2021abr from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021abr.vue'
import estadosFinancieros2021may from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021may.vue'
import estadosFinancieros2021jun from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021jun.vue'
import estadosFinancieros2021jul from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021jul.vue'
import estadosFinancieros2021ago from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021ago.vue'
import estadosFinancieros2021sep from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021sep.vue'
import estadosFinancieros2021oct from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021oct.vue'
import estadosFinancieros2021nov from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021nov.vue'
import estadosFinancieros2021dic from '../views/ArmonizacionContable/estadosFinancieros/2021/estadoFinancierox2021dic.vue'
import estadosFinancieros2020ene from '../views/ArmonizacionContable/estadosFinancieros/2020/estadoFinancierox2020ene.vue'
import estadosFinancieros2020feb from '../views/ArmonizacionContable/estadosFinancieros/2020/estadoFinancierox2020feb.vue'
import cuentapublicapresupuestal1T24 from '../views/ArmonizacionContable/CuentaPublica1/2024/cuentapublicapresupuestal1T24.vue'
import cuentapublicafinanciera1T24 from '../views/ArmonizacionContable/CuentaPublica1/2024/cuentapublicafinanciera1T24.vue'
import cuentapublicapresupuestal2T from '../views/ArmonizacionContable/CuentaPublica1/2023/cuentapublicapresupuestal2T.vue'
import cuentapublicafinanciera2T from '../views/ArmonizacionContable/CuentaPublica1/2023/cuentapublicafinanciera2T.vue'
import cuentapublicapresupuestal3T from '../views/ArmonizacionContable/CuentaPublica1/2023/cuentapublicapresupuestal3T.vue'
import cuentapublicapresupuestal234T from '../views/ArmonizacionContable/CuentaPublica1/2023/cuentapublicapresupuestal234T.vue'
import cuentapublicafinanciera3T from '../views/ArmonizacionContable/CuentaPublica1/2023/cuentapublicafinanciera3T.vue'
import cuentapublicafinanciera234T from '../views/ArmonizacionContable/CuentaPublica1/2023/cuentapublicafinanciera234T.vue'
import cuentapublicafinanciera4T from '../views/ArmonizacionContable/CuentaPublica1/2020/cuentapublicafinanciera4T.vue'
import cuentapublicapresupuestal4T from '../views/ArmonizacionContable/CuentaPublica1/2020/cuentapublicapresupuestal4T.vue'
import cuentapublicafinanciera214T from '../views/ArmonizacionContable/CuentaPublica1/2021/cuentapublicafinanciera214T.vue'
import cuentapublicapresupuestal214T from '../views/ArmonizacionContable/CuentaPublica1/2021/cuentapublicapresupuestal214T.vue'
import cuentapublicafinanciera224T from '../views/ArmonizacionContable/CuentaPublica1/2022/cuentapublicafinanciera224T.vue'
import cuentapublicapresupuestal224T from '../views/ArmonizacionContable/CuentaPublica1/2022/cuentapublicapresupuestal224T.vue'
import disciplina231T from '../views/ArmonizacionContable/Leydedisciplina1/2023/disciplina231T.vue'
import disciplina232T from '../views/ArmonizacionContable/Leydedisciplina1/2023/disciplina232T.vue'
import disciplina233T from '../views/ArmonizacionContable/Leydedisciplina1/2023/disciplina233T.vue'
import disciplina234T from '../views/ArmonizacionContable/Leydedisciplina1/2023/disciplina234T.vue'
import disciplina241T from '../views/ArmonizacionContable/Leydedisciplina1/2024/disciplina241T.vue'
import disciplina242T from '../views/ArmonizacionContable/Leydedisciplina1/2024/disciplina242T.vue'
import disciplina243T from '../views/ArmonizacionContable/Leydedisciplina1/2024/disciplina243T.vue'
import disciplina244T from '../views/ArmonizacionContable/Leydedisciplina1/2024/disciplina244T.vue'
import normas231T from '../views/ArmonizacionContable/normasemitidasporelconac1/2023/normas231T.vue'
import normas232T from '../views/ArmonizacionContable/normasemitidasporelconac1/2023/normas232T.vue'
import normas233T from '../views/ArmonizacionContable/normasemitidasporelconac1/2023/normas233T.vue'
import normas234T from '../views/ArmonizacionContable/normasemitidasporelconac1/2023/normas234T.vue'
import normas241T from '../views/ArmonizacionContable/normasemitidasporelconac1/2024/normas241T.vue'
import normas242T from '../views/ArmonizacionContable/normasemitidasporelconac1/2024/normas242T.vue'
import normas243T from '../views/ArmonizacionContable/normasemitidasporelconac1/2024/normas243T.vue'
import normas244T from '../views/ArmonizacionContable/normasemitidasporelconac1/2024/normas244T.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/revista-institucional',
    name: 'revista',
    component: Revista
  },
  
  {
    path: '/revista-institucional/2_edicion',
    name: 'revista_2edicion',
    component: Revista2daEdicion
  },
  {
    path: '/comite-de-etica',
    name: 'comiteEtica',
    component: ComiteEtica
  },
  {
    path: '/encuestas',
    name: 'encuestas',
    component: encuestas
  },
  {
    path: '/Convocatoriasdibu2024',
    name: 'Convocatoriasdibu2024',
    component: Convocatoriasdibu2024
  },
  {
    path: '/Convocatoriasfoto2024',
    name: 'Convocatoriasfoto2024',
    component: Convocatoriasfoto2024
  },
  {
    path: '/FormularioInscripcion',
    name: 'FormularioInscripcion',
    component: FormularioInscripcion
  },
  {
    path: '/archivistica',
    name: 'archivistica',
    component: archivistica
  },
  {
    path: '/conocenos',
    name: 'conocenos',
    component: conocenos
  },
  {
    path: '/armonizacionContable',
    name: 'armonizacionContable',
    component: armonizacionContable
  },
  {
    path: '/bibliotecaVirtual',
    name: 'bibliotecaVirtual',
    component: bibliotecaVirtual
  },
  {
    path: '/avisoDePrivacidad',
    name: 'avisoDePrivacidad',
    component: avisoPrivacidad
  },
  {
    path: '/programas',
    name: 'programas',
    component: programas
  },
  {
    path: '/palabras-director',
    name: 'programasDirector',
    component: programasDirector
  },
  {
    path: '/bienvenida',
    name: 'bienvenida',
    component: bienvenida
  },
  {
    path: '/directorio-funcionarios',
    name: 'directorioFuncionarios',
    component: directorioFuncionarios
  },
  {
    path: '/organigrama',
    name: 'organigrama',
    component: organigrama
  },
  {
    path: '/formatos-conac',
    name: 'formatosCONAC',
    component: formatosCONAC
  },
  {
    path: '/Normasemitidasporelconac',
    name: 'normasemitidasporelconac',
    component: normasemitidasporelconac
  },
  {
    path: '/normasemitidasporelconac1/normas-231T',
    name: 'normas231T',
    component: normas231T
  },
  {
    path: '/normasemitidasporelconac1/normas-232T',
    name: 'normas232T',
    component: normas232T
  },
  {
    path: '/normasemitidasporelconac1/normas-233T',
    name: 'normas233T',
    component: normas233T
  },
  {
    path: '/normasemitidasporelconac1/normas-234T',
    name: 'normas234T',
    component: normas234T
  },
  {
    path: '/normasemitidasporelconac1/normas-241T',
    name: 'normas241T',
    component: normas241T
  },
  {
    path: '/normasemitidasporelconac1/normas-242T',
    name: 'normas242T',
    component: normas242T
  },
  {
    path: '/normasemitidasporelconac1/normas-243T',
    name: 'normas243T',
    component: normas243T
  },
  {
    path: '/normasemitidasporelconac1/normas-244T',
    name: 'normas244T',
    component: normas244T
  },
  {
    path: '/ley-de-disciplina',
    name: 'leydedisciplina',
    component: leydedisciplina
  },
  {
    path: '/Leydedisciplina1/disciplina-231T',
    name: 'disciplina231T',
    component: disciplina231T
  },
  {
    path: '/Leydedisciplina1/disciplina-232T',
    name: 'disciplina232T',
    component: disciplina232T
  },
  {
    path: '/Leydedisciplina1/disciplina-233T',
    name: 'disciplina233T',
    component: disciplina233T
  },
  {
    path: '/Leydedisciplina1/disciplina-234T',
    name: 'disciplina234T',
    component: disciplina234T
  },
  {
    path: '/Leydedisciplina1/disciplina-241T',
    name: 'disciplina241T',
    component: disciplina241T
  },
  {
    path: '/Leydedisciplina1/disciplina-242T',
    name: 'disciplina242T',
    component: disciplina242T
  },
  {
    path: '/Leydedisciplina1/disciplina-243T',
    name: 'disciplina243T',
    component: disciplina243T
  },
  {
    path: '/Leydedisciplina1/disciplina-244T',
    name: 'disciplina244T',
    component: disciplina244T
  },
  
  {
    path: '/formatos-conac/estados-financieros',
    name: 'estadosFinancieros',
    component: estadosFinancieros
  },
  {
    path: '/formatos-conac/estados-financieros2023',
    name: 'estadosFinancieros2023',
    component: estadosFinancieros2023
  },
  {
    path: '/formatos-conac/estados-financieros2024ene',
    name: 'estadosFinancieros2024ene',
    component: estadosFinancieros2024ene
  },
  {
    path: '/formatos-conac/estados-financieros2024feb',
    name: 'estadosFinancieros2024feb',
    component: estadosFinancieros2024feb
  },
  {
    path: '/formatos-conac/estados-financieros2023ene',
    name: 'estadosFinancieros2023ene',
    component: estadosFinancieros2023ene
  },
  {
    path: '/formatos-conac/estados-financieros2023feb',
    name: 'estadosFinancieros2023feb',
    component: estadosFinancieros2023feb
  },
  {
    path: '/formatos-conac/estados-financieros2023mar',
    name: 'estadosFinancieros2023mar',
    component: estadosFinancieros2023mar
  },
  {
    path: '/formatos-conac/estados-financieros2023abr',
    name: 'estadosFinancieros2023abr',
    component: estadosFinancieros2023abr
  },
  {
    path: '/formatos-conac/estados-financieros2023may',
    name: 'estadosFinancieros2023may',
    component: estadosFinancieros2023may
  },
  {
    path: '/formatos-conac/estados-financieros2023jun',
    name: 'estadosFinancieros2023jun',
    component: estadosFinancieros2023jun
  },
  {
    path: '/formatos-conac/estados-financieros2023jul',
    name: 'estadosFinancieros2023jul',
    component: estadosFinancieros2023jul
  },
  {
    path: '/formatos-conac/estados-financieros2023ago',
    name: 'estadosFinancieros2023ago',
    component: estadosFinancieros2023ago
  },
  {
    path: '/formatos-conac/estados-financieros2023sep',
    name: 'estadosFinancieros2023sep',
    component: estadosFinancieros2023sep
  },
  {
    path: '/formatos-conac/estados-financieros2022ene',
    name: 'estadosFinancieros2022ene',
    component: estadosFinancieros2022ene
  },
  {
    path: '/formatos-conac/estados-financieros2022feb',
    name: 'estadosFinancieros2022feb',
    component: estadosFinancieros2022feb
  },
  {
    path: '/formatos-conac/estados-financieros2022mar',
    name: 'estadosFinancieros2022mar',
    component: estadosFinancieros2022mar
  },
  {
    path: '/formatos-conac/estados-financieros2022abr',
    name: 'estadosFinancieros2022abr',
    component: estadosFinancieros2022abr
  },
  {
    path: '/formatos-conac/estados-financieros2022may',
    name: 'estadosFinancieros2022may',
    component: estadosFinancieros2022may
  },
  {
    path: '/formatos-conac/estados-financieros2022jun',
    name: 'estadosFinancieros2022jun',
    component: estadosFinancieros2022jun
  },
  {
    path: '/formatos-conac/estados-financieros2022jul',
    name: 'estadosFinancieros2022jul',
    component: estadosFinancieros2022jul
  },
  {
    path: '/formatos-conac/estados-financieros2022ago',
    name: 'estadosFinancieros2022ago',
    component: estadosFinancieros2022ago
  },
  {
    path: '/formatos-conac/estados-financieros2022sep',
    name: 'estadosFinancieros2022sep',
    component: estadosFinancieros2022sep
  },
  {
    path: '/formatos-conac/estados-financieros2022oct',
    name: 'estadosFinancieros2022oct',
    component: estadosFinancieros2022oct
  },
  {
    path: '/formatos-conac/estados-financieros2022nov',
    name: 'estadosFinancieros2022nov',
    component: estadosFinancieros2022nov
  },
  {
    path: '/formatos-conac/estados-financieros2022dic',
    name: 'estadosFinancieros2022dic',
    component: estadosFinancieros2022dic
  },
  {
    path: '/formatos-conac/estados-financieros2021ene',
    name: 'estadosFinancieros2021ene',
    component: estadosFinancieros2021ene
  },
  {
    path: '/formatos-conac/estados-financieros2021feb',
    name: 'estadosFinancieros2021feb',
    component: estadosFinancieros2021feb
  },
  {
    path: '/formatos-conac/estados-financieros2021mar',
    name: 'estadosFinancieros2021mar',
    component: estadosFinancieros2021mar
  },
  {
    path: '/formatos-conac/estados-financieros2021abr',
    name: 'estadosFinancieros2021abr',
    component: estadosFinancieros2021abr
  },
  {
    path: '/formatos-conac/estados-financieros2021may',
    name: 'estadosFinancieros2021may',
    component: estadosFinancieros2021may
  },
  {
    path: '/formatos-conac/estados-financieros2021jun',
    name: 'estadosFinancieros2021jun',
    component: estadosFinancieros2021jun
  },
  {
    path: '/formatos-conac/estados-financieros2021jul',
    name: 'estadosFinancieros2021jul',
    component: estadosFinancieros2021jul
  },
  {
    path: '/formatos-conac/estados-financieros2021ago',
    name: 'estadosFinancieros2021ago',
    component: estadosFinancieros2021ago
  },
  {
    path: '/formatos-conac/estados-financieros2021sep',
    name: 'estadosFinancieros2021sep',
    component: estadosFinancieros2021sep
  },
  {
    path: '/formatos-conac/estados-financieros2021oct',
    name: 'estadosFinancieros2021oct',
    component: estadosFinancieros2021oct
  },
  {
    path: '/formatos-conac/estados-financieros2021nov',
    name: 'estadosFinancieros2021nov',
    component: estadosFinancieros2021nov
  },
  {
    path: '/formatos-conac/estados-financieros2021dic',
    name: 'estadosFinancieros2021dic',
    component: estadosFinancieros2021dic
  },
  {
    path: '/formatos-conac/estados-financieros2020ene',
    name: 'estadosFinancieros2020ene',
    component: estadosFinancieros2020ene
  },
  {
    path: '/formatos-conac/estados-financieros2020feb',
    name: 'estadosFinancieros2020feb',
    component: estadosFinancieros2020feb
  },
  {
    path: '/cuenta-publica',
    name: 'cuentaPublica',
    component: cuentaPublica
  },
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal1T24',
    name: 'cuentapublicapresupuestal1T24',
    component: cuentapublicapresupuestal1T24
  },
  {
    path: '/cuenta-publica1/cuentapublica-financiera1T24',
    name: 'cuentapublicafinanciera1T24',
    component: cuentapublicafinanciera1T24
  },
  
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal2T',
    name: 'cuentapublicapresupuestal2T',
    component: cuentapublicapresupuestal2T
  },
  {
    path: '/cuenta-publica1/cuentapublica-financiera2T',
    name: 'cuentapublicafinanciera2T',
    component: cuentapublicafinanciera2T
  },
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal3T',
    name: 'cuentapublicapresupuestal3T',
    component: cuentapublicapresupuestal3T
  },
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal4T',
    name: 'cuentapublicapresupuestal4T',
    component: cuentapublicapresupuestal4T
  },
  {
    path: '/cuenta-publica1/cuentapublica-financiera3T',
    name: 'cuentapublicafinanciera3T',
    component: cuentapublicafinanciera3T
  },
  {
    path: '/cuenta-publica1/cuentapublica-financiera4T',
    name: 'cuentapublicafinanciera4T',
    component: cuentapublicafinanciera4T
  },
  {
    path: '/cuenta-publica1/cuentapublica-financiera214T',
    name: 'cuentapublicafinanciera214T',
    component: cuentapublicafinanciera214T
  },
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal214T',
    name: 'cuentapublicapresupuestal214T',
    component: cuentapublicapresupuestal214T
  },
  {
    path: '/cuenta-publica1/cuentapublica-financiera224T',
    name: 'cuentapublicafinanciera224T',
    component: cuentapublicafinanciera224T
  },
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal224T',
    name: 'cuentapublicapresupuestal224T',
    component: cuentapublicapresupuestal224T
  },
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal234T',
    name: 'cuentapublicapresupuestal234T',
    component: cuentapublicapresupuestal234T
  },
  {
    path: '/cuenta-publica1/cuentapublica-financiera234T',
    name: 'cuentapublicafinanciera234T',
    component: cuentapublicafinanciera234T
  },
 
  {
    path: '/cuenta-publica1/cuentapublica-presupuestal4T',
    name: 'cuentapublicapresupuestal4T',
    component: cuentapublicapresupuestal4T
  },
  
  {
    path: '/indicadores-resultado',
    name: 'indicadoresResultados',
    component: indicadoresResultados
  },
  {
    path: '/programa-anual-de-adquisiciones',
    name: 'programaAnualAdquisiciones',
    component: programaAnualAdquisiciones
  },
  {
    path: '/licitacion-2017',
    name: 'licitacion2017',
    component: licitacion2017
  },
  {
    path: '/licitacion-2018',
    name: 'licitacion2018',
    component: licitacion2018
  },
  {
    path: '/licitacion-2019',
    name: 'licitacion2019',
    component: licitacion2019
  },
  {
    path: '/inventario-de-bienes',
    name: 'inventarioDeBienes',
    component: inventarioDeBienes
  },
  {
    path: '/cuencas',
    name: 'cuencas',
    component: cuencas
  },
  {
    path: '/saneamiento',
    name: 'saneamiento',
    component: saneamiento
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="container mt-3">
    <div class="col-md-12 mt-3 mb-3 pe-5" style="text-align: right">
      <a href="/armonizacionContable" class="btn-agua"
        ><i class="fas fa-arrow-left"></i> Atras</a
      >
      <a href="/" class="ms-2 btn-agua"><i class="fas fa-house"></i> Inicio</a>
    </div>
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <p class="title1_vino">
          Licitaciones 2018
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Licitación No. IR-20750-001-2018 y Licitación No. IR-20750-002-2018</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Base Licitación No. IR-20750-001-2018	</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Base Licitación No. IR-20750-002-2018</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Licitación No. IR-20750-003-2018 (SEGUNDA LICITACIÓN)</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Bases</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Acta Circustanciada</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Acta de Junta de Aclaraciones</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Acta de Apertura de Proposiciones Técnicas y Económicas	</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Dictamen Técnico, Administrativo, Legal y Financiero	</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Acta de Fallo</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <td scope="col" class="bg-vino-secondary text-white">
                <h4 class="mb-0">Licitación No. IR-20750-004-2018 (SEGUNDA LICITACIÓN)</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/1.%20Acuerdo_General_Ley_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Bases</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.%20Sistema%20Institucional%20de%20Archivos.pdf'
                  "
                  target="_blank"
                  >Acta Circustanciada</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <a
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.1.-%20Nombramiento_Coordinadora_de_Archivos_2023.pdf'
                  "
                  target="_blank"
                  >Acta de Junta de Aclaraciones</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Acta de Apertura de Proposiciones Técnicas y Económicas	</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Dictamen Técnico, Administrativo, Legal y Financiero	</a
                >
              </td>
            </tr>
            <tr>
              <td >
                <a 
                  :href="
                    urlServe +
                    'ARCHIVISTICA/PDF/2023/2.2.-%20Nombramiento_Responsable_de_Correspondencia_2023.pdf'
                  "
                  target="_blank"
                  >Acta de Fallo</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<template>
    <div>
        <!-- banner -->
    <div class="container-subslide">
      <div class="row">
        <div class="col-md-12">
          <div class="text-slide">
            <h1 class="title">Conócenos</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- contenedores de apartados -->
    <div class="container mb-5 mt-4">
            <div class="row">
                <!-- Divs -->
                <div class="col-md-12 mb-3 pe-5" style="text-align:right">
                    <a href="/" class="btn-agua"><i class="fas fa-home"></i> Inicio</a>
                </div>
                <div class="col-md-12 mt-5">
                    <div class="title-contend">
                        <p class="title1_vino">Conócenos</p>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xs-12 mt-4 order-2 order-sm-2 order-md-1 order-lg-1">             
                    <div class="card-apart tab-hv card card-header bg-white mb-2"> <!--card solita----->
                        <a class="btn btn-link" href="/bienvenida" >
                            <span>Bienvenida</span>
                            <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                        </a>
                    </div>
                    <div class="card-apart card tab-hv card-header bg-white mb-2"> <!--card solita----->
                        <a class="btn btn-link" href="/palabras-director" >
                            <span>Palabras por el Director</span>
                            <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                        </a>
                    </div>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item mb-2">
                            <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button tab-hv" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Misión y Visión
                            </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>Misión:</strong> Establecer mecanismos, métodos y sistemas de planeación, programación, financiamiento y operación, que propicien y logren la mayor racionalidad en el aprovechamiento sustentable del agua en el Estado, para consumo humano, industrial y agropecuario, para beneficio del desarrollo social y económico del Estado.<br><br>
                                <strong>Visión:</strong>Ser un organismo rector especializado en la planeación, financiamiento, operación y normatividad del sector hídrico estatal, fortalecedor de los organismos operadores y propulsor de tecnologías.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button tab-hv collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Objetivos y Proyectos
                            </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>Objetivos.</strong><ul><li>Programa agua limpia</li><li>Programa cultura del agua</li><li>Programa consejos de cuenca</li></ul><br><br>
                                <strong>Proyectos</strong><ul><li>Elaboración y supervisión de proyectos ejecutivos que incrementen la cobertura y calidad de los servicios de agua, así como sus diversos usos.</li>
                                <li>Elevar la calidad de los servicios de agua potable y alcantarillado.</li>
                                <li>Ampliar la cobertura de los servicios de agua potable.</li>
                                <li>Fomentar el uso racional y adecuado del agua.</li>
                                <li>Elevar la calidad de los servicios de agua potable y alcantarillado.</li>
                                <li>Fomentar el cuidado del agua.</li></ul>
                            </div>
                            </div>
                        </div>
                        <br>
                        <div class="card-apart tab-hv card card-header bg-white mb-2"> <!--card solita----->
                            <button class="btn btn-link" type="button">
                                <span>Integridad Pública</span>
                                <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                            </button>
                        </div>
                        <div class="card-apart tab-hv card card-header bg-white mb-2"> <!--card solita----->
                            <button class="btn btn-link" href="/directorio-funcionarios" >
                                <span></span>
                                <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                            </button>
                        </div>
                        <div class="card-apart tab-hv card card-header bg-white mb-2"> <!--card solita----->
                            <a class="btn btn-link" href="/organigrama" >
                                <span>Organigrama General</span>
                                <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                            </a>
                        </div>
                        <div class="card-apart tab-hv card card-header bg-white mb-2"> <!--card solita----->
                            <a class="btn btn-link" href="https://chiapas.gob.mx/funcionarios/estatal/ejecutivo/instituto-agua" >
                                <span>Directorio de funcionarios</span>
                                <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                            </a>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header " id="headingThree">
                            <button class="accordion-button tab-hv collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Código de conducta
                            </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>Honradez</strong><br>En el desarrollo de sus actividades los servidores públicos deben actuar con la máxima rectitud posible, sin pretender obtener con base en el cargo, empleo o comisión que desempeñan ventaja o provecho alguno, para sí o para terceros. Asimismo, deben abstenerse de aceptar o buscar prestaciones o compensaciones provenientes de cualquier persona, evitando de esta manera la realización de conductas, que pudieran poner en duda su integridad o disposición para el cumplimiento de los deberes propios del cargo.<br><br>
                                <strong>Legalidad</strong><br>El servidor público debe ejercer sus actividades con estricto apego al marco jurídico vigente, por ende, se encuentra obligado a conocer, respetar y cumplir la Constitución Política de los Estados Unidos Mexicanos, la Constitución Política del Estado de Chiapas, la Ley Orgánica de la Administración Publica del Estado de Chiapas, y además leyes y reglamentos que regulan sus funciones.<br><br>
                                <strong>Prudencia</strong><br>En aquellos casos en que el servidor público se encuentre ante situaciones no previstas por las leyes; éste deberá utilizar la razón, a efecto de ser capaz de distinguir lo bueno y lo malo, procurando por encima de cualquier interés particular el bienestar colectivo.<br><br>
                                <strong>Imparcialidad y Objetividad</strong><br>Los servidores públicos se encuentran comprometidos a proceder de manera correcta durante el ejercicio de las funciones que desempeñan, dirigiendo su conducta de acuerdo a las normas legales que para el caso se encuentren establecidas, dejando a un lado cualquier tipo de influencias extrañas que pudieran desviarla, actuando sin conocer preferencias o privilegios indebidos a organización o persona alguna; evitando que influya en su juicio y conducta, intereses externos que perjudiquen o beneficien a personas o grupos en detrimento del bienestar de la sociedad, desarrollando su actuación sin aprensiones o prejuicios, apegándose en todo momento a las reglas institucionales que al efecto existan.<br><br>
                                <strong>Compromiso</strong><br>Los servidores públicos deberán procurar que durante el desarrollo de sus funciones vaya más allá del simple cumplimiento de su deber, pretendiendo que el ejercicio de sus actividades sea efectivo, eficaz y eficiente.<br>Publicado en el Periódico Oficial No. 209 (PDF, Tamaño 880 Kb.)<br>
                            </div>
                            </div>
                        </div>
                        <br>
                        <div class="card-apart tab-hv card card-header bg-white mb-2"> <!--card solita----->
                            <button class="btn btn-link" type="button">
                                <span>Código de ética</span>
                                <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                            </button>
                        </div>
                        <div class="card-apart tab-hv card card-header bg-white mb-2"> <!--card solita----->
                            <button class="btn btn-link" type="button">
                                <span>Ley de Aguas</span>
                                <span class="descargar"><i class="fa-solid fa-arrow-right"></i></span>
                            </button>
                        </div>
                        
                    </div>
                </div>
                <!-- Imagen -->
                <div class="col-md-12 col-lg-6 col-xs-12 order-sm-1 order-md-2 order-lg-2">
                    <div class="row d-flex justify-content-end">
                        <div class="container-img-right">
                            <img src="@/assets/images/prueba.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>

    </div>
    <div class="container-mision pb-5" style="background-color:#f6f6f6">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-5">
                    <div class="title-contend">
                        <p class="title1_vino">Marco Jurídico</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="Subtitle1_vino">Decreto de creación</p>
                    </div>
                    <p>El agua, como recurso no renovable y parte del patrimonio nacional, requiere de estrategias y políticas publicas de largo alcance y mayor eficiencia, para su uso sustentable, en lo doméstico, comercial, industrial y agropecuario; que asegure una mayor cobertura y eficientice el uso actual, preservando el recurso par las nuevas generaciones; y que además se propongan políticas y estrategias para prevención de emergencias y desastres hidrometeorológicos.</p>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="Subtitle1_vino">Manual de Inducción</p>
                    </div>
                    <p>El Instituto Estatal del Agua es un organismo descentralizado de la Administración Pública Estatal, que tiene como objetivos fundamentales establecer mecanismos, métodos y sistemas de planeación, programación, financiamiento y operación que propicien y logren la mayor racionalidad en el aprovechamiento sustentable del agua en nuestro Estado.</p>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="Subtitle1_vino">Reglamiento Interior</p>
                    </div>
                    <p>Es indispensable contar con una Institución que promueva e investigue nuevas técnicas y tecnologías del agua, que estimule el ahorro, fortalezca la cultura, el aprovechamiento y la conservación del recurso, vinculándose con sistemas de información y colaboración científica y tecnológica, con entidades públicas y privadas, nacionales y extranjeras que se relacionen con la materia y que promueva e impulse ante la sociedad, una amplia concientización hacia una mayor cultura del agua propiciando la creación de órganos ciudadanos que generen acciones a favor del mejor uso y aprovechamiento de la misma, fortaleciendo el derecho de los usuarios, el cual asesorará en lo técnico, financiero, administrativo y operativo, a los organismos operadores municipales de agua potable y coadyuvará con ellos en los programas y proyectos de agua, drenaje y saneamiento que propicien y logren la mayor racionalidad del aprovechamiento.</p>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="Subtitle1_vino">Manual de Organización</p>
                    </div> 
                    <p>El Instituto Estatal del Agua, como organismo descentralizado de la Administración Pública, fomenta y fortalece a los entes operadores y comités comunitarios para que se responsabilicen del buen funcionamiento de la infraestructura hidráulica e hidrológica que se construya en nuestro Estado, así también, valida proyectos ejecutivos de agua potable, alcantarillado sanitario y saneamiento, certifica los laboratorios de calidad del agua y promueve su educación y cultura.</p>
                </div>
                <div class="col-md-12">
                    <div class="title-contend">
                        <p class="Subtitle1_vino">Manual de Procedimientos</p>
                    </div> 
                    <p>El Instituto Estatal del Agua, como organismo descentralizado de la Administración Pública, fomenta y fortalece a los entes operadores y comités comunitarios para que se responsabilicen del buen funcionamiento de la infraestructura hidráulica e hidrológica que se construya en nuestro Estado, así también, valida proyectos ejecutivos de agua potable, alcantarillado sanitario y saneamiento, certifica los laboratorios de calidad del agua y promueve su educación y cultura.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- loader -->
    <!-- <div class="loader-wrapper">
      <Loader v-if="isLoading"/>
    </div>  -->
    </div>
</template>
<script lang="ts">
import Loader from '../Loader.vue';
export default{
  components:{
    Loader,
  },
  data(){
    return{
      isLoading: true,
    };
  },
  mounted()
  {
    setTimeout(() => {
      this.isLoading = false;
    },1000);
  }
}
</script>